import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { apiGetConfigList, apiGetEmCount, apiGetUserInfo } from '../api';
import main, { AppLocalKey } from '../main';
import { parseLocationParams, showToast } from '../utils/utils';
import FloatCustomer from './homePage/floatCustomer';

let isWeixin = true;
export default function MainPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [over, setOver] = useState(false);
  const [showCustomer, setShowCustomer] = useState(false);
  useEffect(() => {
    let ua = window.navigator.userAgent.toLowerCase();
    let params = parseLocationParams(window.location.search);
    if (!!params.cid) {
      main.nextBindEm = params.cid;
    }
    isWeixin = ua.includes('micromessenger');
    let openId = localStorage.getItem(main.appId);
    let uri = window.location.href;
    let redirect_uri = encodeURIComponent(uri);
    if (isWeixin && !window.location.search.includes('code') && !openId) {
      document.location.replace(
        `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${main.appId}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
      );
    } else if (isWeixin && window.location.search.includes('code') && !openId) {
      let t = window.location.search.split('?')[1].split('&');
      let code = '';
      for (let s of t) {
        if (s.includes('code')) {
          code = s.split('=')[1];
          break;
        }
      }
      if (code) {
        main.code = code;
      }
    }
    if (openId) {
      main.openId = openId;
    }
    checkLoginKey();
    loadConfig();
  }, []); // eslint-disable-line
  useEffect(() => {
    // 客服悬浮按钮 部分页面不显示
    const hidePage = ['/customer', '/complaint-index', '/complaint', '/complaint-list', '/complaint-detail'];
    let hide = hidePage.includes(location.pathname);
    setShowCustomer(!hide);
  }, [location]); // eslint-disable-line

  function checkLoginKey() {
    if (main.isIos14) {
      main.enterUrl = window.location.href;
    }
    let key = localStorage.getItem(AppLocalKey.loginToken);
    main.loginKey = key;
    if (!key) {
      setOver(true);
      routeToPage('/login');
    } else {
      setOver(false);
      loadUserInfo(key);
    }
    // let isLogin = localStorage.getItem('isLogin');
    // if (isLogin) {
    //   if (location.pathname.includes('/login') || location.pathname === '/') {
    //     navigate('/home', { replace: true });
    //   }
    // } else if (!whiteList.includes(location.pathname)) {
    //   navigate('/login', { replace: true });
    // }
  }

  async function loadConfig() {
    if (main.configList?.length) return;
    try {
      let configRes = await apiGetConfigList();
      let configData = configRes.data;
      if (configData.code === 200) {
        main.configList = configData.data;
      }
    } catch (e: any) {
      console.log(e);
    }
  }

  async function loadUserInfo(token: string) {
    try {
      let res = await apiGetUserInfo(token);
      let data = res.data;
      setOver(true);
      if (data.code === 200) {
        main.user = data.data.user;
      }
    } catch (e: any) {
      console.error(e);
    }
    if (main.user?.userId) {
      if (!!main.nextBindEm) {
        routeToPage('/add-em');
      } else {
        let res = await apiGetEmCount();
        let data = res.data;
        if (data.data <= 0) {
          routeToPage('/em-list', { backToOut: true });
        } else {
          let url = location.pathname;
          url = '/home';
          routeToPage(url);
        }
      }
    } else {
      routeToPage('/login');
    }
  }

  function routeToPage(page: string, state?: any) {
    if (!isWeixin && !main.isDev) {
      showToast('请在微信环境内浏览，避免使用故障');
    }
    navigate(page, { replace: true, state });
  }

  if (!over) {
    return (
      <div className="vw-100 vh-100 d-flex-c ai-center jc-center pt-40 pb-20">
        <img src="/pic/logo.png" className="icon-80 mb-20" alt="" />
        <div className="text-color-p flex-1">网司令</div>
        <div className="text-color-p">WANGSILING</div>
      </div>
    );
  }

  return (
    <div>
      <Outlet />
      {showCustomer && (
        <FloatCustomer onClick={() => navigate('/customer')} onComplaint={() => navigate('/complaint-index')} />
      )}
    </div>
  );
}
