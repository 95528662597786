import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { apiGetPackBuyLogList } from '../../api';
import AppBar from '../../components/appBar';
import Button from '../../components/button';
import NoData from '../../components/noData';
import Page from '../../components/page';
import TabBar from '../../components/tabBar';
import main from '../../main';
import { PackBuyLogStatus, PackageType } from '../../utils/dict';

const packTypes = ['all', PackageType.basicpackage, PackageType.fuelpack];
export default function PackOrderListPage() {
  const [tabValue, setTabValue] = useState(0);
  const [packType, setPackType] = useState(packTypes[0]);
  const [list, setList] = useState<PackBuyLog[]>([]);
  useEffect(() => {
    loadList();
  }, []);

  async function loadList() {
    try {
      let res = await apiGetPackBuyLogList(main.currentEm?.id!);
      let data = res.data;
      if (data.code === 200) {
        setList(data.rows ?? []);
      }
    } catch (e: any) {
      console.error(e);
    }
  }

  let typeValueList = PackBuyLogStatus.getTypeList();
  let typeLabelList = typeValueList.map((i) => PackBuyLogStatus.getTypeStr(i));
  let countMap: any = {};
  list.forEach((i) => {
    if (!countMap[i.status!]) {
      countMap[i.status!] = 1;
    } else {
      countMap[i.status!]++;
    }
  });
  let _list = list.filter((i) => i.status === typeValueList[tabValue]);
  if (packType !== 'all') {
    _list = _list.filter((i) => i.packageType === packType);
  }

  return (
    <Page className="d-flex-c" contentClassName="flex-1 d-flex-c h-1" appBar={<AppBar title="套餐管理" />}>
      <TabBar
        className="mt-10 border-bottom"
        badges={typeValueList.map((i) => countMap[i])}
        options={typeLabelList}
        value={typeLabelList[tabValue as number]}
        onChange={(v) => setTabValue(typeLabelList.indexOf(v))}
      />
      <div className="d-flex-r p-10 pl-20 pr-20 bg-white border-bottom">
        <Button
          className="mr-20"
          size="xs"
          outline={packType !== packTypes[0]}
          type={packType === packTypes[0] ? 'primary' : 'default'}
          onClick={() => setPackType(packTypes[0])}
        >
          全部
        </Button>
        <Button
          className="mr-20"
          size="xs"
          outline={packType !== packTypes[1]}
          type={packType === packTypes[1] ? 'primary' : 'default'}
          onClick={() => setPackType(packTypes[1])}
        >
          基础包
        </Button>
        <Button
          className="mr-20"
          size="xs"
          outline={packType !== packTypes[2]}
          type={packType === packTypes[2] ? 'primary' : 'default'}
          onClick={() => setPackType(packTypes[2])}
        >
          加油包
        </Button>
      </div>
      <div className="flex-1 h-1 overflow-y bg-white ml-20 mr-20">
        {!_list.length && <NoData tips="暂无套餐" />}
        {_list.map((i, idx) => (
          <div
            key={idx}
            className="mt-10 pt-26 pb-10 pl-14 pr-14 border border-radius-5 border-color-gray d-flex-r ai-center pos-r"
          >
            <div
              className={`pt-2 pb-2 pl-8 pr-8 ${
                i.packageType === PackageType.basicpackage ? 'bg-primary' : 'bg-origin'
              } text-color-white font-size-12 border-radius-tl-5 border-radius-br-5 pos-a l-0 t-0`}
            >
              {i.packageType === PackageType.basicpackage ? '基础包' : '加油包'}
            </div>
            <div className="flex-1">
              <div className="d-flex-r">
                <div className="mr-10">
                  <div className="d-flex-r ai-center">
                    {i.packDetailName}
                    {i.isSend === 'Y' && (
                      <div className="ml-5 p-3 pl-5 pr-5 bg-p border-radius-4 text-color-white">赠</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="font-size-12 text-color-9 mt-5">
                有效期: {dayjs(i.startEffectTime).format('YYYY-MM-DD')}至{dayjs(i.endEffectTime).format('YYYY-MM-DD')}
              </div>
            </div>
            <div className="font-size-18 font-widget-600 text-color-red">￥{i.orderPrice}</div>
          </div>
        ))}
      </div>
      {/* <div className="bg-white pl-20 pr-20 pt-10 pb-10">
        <Button type="primary" long size="md" onClick={() => navigate('/select-pack')}>
          去订购
        </Button>
      </div> */}
    </Page>
  );
}
