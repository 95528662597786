import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LoadingMask, modelParsePrice, showToast } from '../../../utils/utils';
import { apiGetPayOrderDetail } from '../../../api';
import Page from '../../../components/page';
import AppBar from '../../../components/appBar';
import NoData from '../../../components/noData';
import { PackageType, PayOrderStatus, PayType, PackBuyLogStatus } from '../../../utils/dict';
import dayjs from 'dayjs';

export default function PayOrderDetailPage() {
  const { state } = useLocation();
  const [order, setOrder] = useState<PayOrder>();
  useEffect(() => {
    loadDetail();
  }, []); // eslint-disable-line

  async function loadDetail() {
    LoadingMask.show('加载中...');
    try {
      let res = await apiGetPayOrderDetail(state);
      let data = res.data;
      if (data.code === 200) {
        setOrder(data.data);
      } else {
        showToast(data.msg ?? '加载详情失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '加载详情失败');
    } finally {
      LoadingMask.hide();
    }
  }

  return (
    <Page className="d-flex-c" contentClassName="flex-1 h-1 overflow-y bg-gray" appBar={<AppBar title="订单详情" />}>
      {!order?.id && <NoData tips="暂无数据" />}
      {!!order && (
        <>
          {order.cardDetailEquityOrder?.activityJson?.img ? (
            <div className="bw-100">
              <img className="bw-100" src={order.cardDetailEquityOrder?.activityJson?.img} alt="" />
            </div>
          ) : (
            <div className="h-20" />
          )}
          <div className="pl-20 pr-20 pb-20">
            <div className="d-flex-r ai-start bg-white border-radius-10 p-10 mb-20">
              <img className="icon-60" src="/pic/icon_coupon_flow_active.png" alt="" />
              <div className="flex-1 h-60 d-flex-c jc-between">
                <div className="d-flex-r">
                  <div className="flex-1 omit-text-2 font-widget-600">{order.packDetailName}</div>
                  <div>¥{order.totalPrice}</div>
                </div>
                <div className="d-flex-r font-size-12 text-color-9 jc-end">X{order.number}</div>
              </div>
            </div>
            <div className="bg-white border-radius-10 p-20 mb-20">
              <div className="d-flex-r ai-center jc-between mb-10">
                <div className="font-size-12">商品总价</div>
                <div>￥{modelParsePrice(order?.totalPrice ?? 0)}</div>
              </div>
              <div className="d-flex-r ai-center jc-between mb-10">
                <div className="font-size-12">优惠</div>
                <div className="text-color-red">￥{modelParsePrice(order?.couponPrice ?? 0)}</div>
              </div>
              <div className="d-flex-r ai-center jc-between mb-10">
                <div className="font-size-12">实付款</div>
                <div className="text-color-red">￥{modelParsePrice(order?.realPrice ?? 0)}</div>
              </div>
              <div className="d-flex-r ai-center jc-between">
                <div className="font-size-12">退订金额</div>
                <div className="text-color-red">￥{modelParsePrice(order?.totalReturnPrice ?? 0)}</div>
              </div>
            </div>
            <div className="bg-white border-radius-10 p-20 mb-20">
              <div className="d-flex-r ai-center jc-between mb-10">
                <div>支付方式</div>
                <div className="text-color-9">{PayType.typeStr(order?.payType)}</div>
              </div>
              <div className="d-flex-r ai-center jc-between mb-10">
                <div>设备号</div>
                <div className="text-color-9">{order.emNumber}</div>
              </div>
              <div className="d-flex-r ai-center jc-between mb-10">
                <div>订单编号</div>
                <div className="text-color-9">{order.id}</div>
              </div>
              <div className="d-flex-r ai-center jc-between mb-10">
                <div>订单状态</div>
                <div className="text-color-9">{PayOrderStatus.getStatusStr(order.status!)}</div>
              </div>
              <div className="d-flex-r ai-center jc-between mb-10">
                <div>套餐类型</div>
                <div className="text-color-9">{PackageType.getTypeStr(order.packageType!)}</div>
              </div>
              <div className="d-flex-r ai-center jc-between mb-10">
                <div>时间类型</div>
                <div className="text-color-9">{order.dateType === 'now' ? '本期' : '下期'}</div>
              </div>
              <div className="d-flex-r ai-center jc-between">
                <div>创建时间</div>
                <div className="text-color-9">{order.createTime}</div>
              </div>
            </div>
            <div className="bg-white border-radius-10 p-20 mb-20">
              <div className="mb-10">包含套餐</div>
              {order?.cardPackOrderDetailList &&
                order!.cardPackOrderDetailList!.map((i, idx) => {
                  return <div key={idx} className="d-flex-r mb-1 jc-between font-size-12 text-color-9">
                    <div>
                      第{i.thisPhases}/{i.totalPhases}期
                    </div>
                    <div>
                      {dayjs(i.startEffectTime!).format('YYYY-MM-DD')} - {dayjs(i.endEffectTime!).format('YYYY-MM-DD')}
                    </div>
                    <div>

                      {PackBuyLogStatus.getTypeStr(i.status!)}
                    </div>
                  </div>

                })
              }
            </div>
          </div>
        </>
      )}
    </Page>
  );
}
