import axios, { AxiosInstance } from 'axios';

// @ts-ignore
const defaultValue = window.appConfig;
class Main {
  api: AxiosInstance = axios.create({
    baseURL: defaultValue.url,
    timeout: 120000,
  });

  platform = '';
  // 是否ios14 签名有问题
  isIos14: boolean = false;
  // 是ios14以上需要保存初始url
  enterUrl: string = '';
  isWexin: boolean = false;
  appName = defaultValue.appName;
  deptId = defaultValue.deptId;
  appId = defaultValue.appId;
  tel400 = defaultValue.tel400;
  customer = defaultValue.customer;
  // 配置了此项 支付时没有openID也不拦截，可测试余额支付
  isDev = !!defaultValue.isDev;
  //-----------------
  // 微信用户授权CODE 在登录成功后获取OPENID
  code?: string;
  // 微信用户授权OPENID 在登录成功后获取OPENID
  openId?: string;
  /// 联通实名小程序
  unionMiniprogram = 'gh_5eac63f4d504';

  // 默认客服链接 参数配置
  wslCustomerUrl = 'wslCustomerUrl';
  // 400电话key 参数配置
  tel400Key = 'wsl400Tel';
  // 复购入口key 参数配置和文件上传
  reBuyEnteryKey = 'rebuyEntery';
  // 配置列表
  configList: Config[] = [];
  // 登录token
  loginKey?: string | null;
  // 当前登录用户
  user?: User;
  // 当前设备
  currentEm?: CurrentEm;
  // 跳转到参与活动
  toActivity: { [x: string]: boolean } = {};
  // 跳转到买套餐
  toBuyPackage: { [x: string]: boolean } = {};
  // 跳转到实名
  toRealName: { [x: string]: boolean } = {};

  /// 套餐流量大于10000时显示不限量
  showUnlimited: number = 10000;
  /** 有广告弹窗 别的购买套餐弹窗不显示 */
  hasAdverties: boolean = false;
  /** 入口带卡号 要绑定 */
  nextBindEm?: string;
  init() {
    let ua = window.navigator.userAgent.toLowerCase();

    var platform = '';
    if (ua.includes('android')) {
      platform = 'android';
    } else if (ua.includes('iphone')) {
      platform = 'ios';
    } else {
      platform = 'unknow';
    }
    this.platform = platform;

    const ver = ua.match(/cpu iphone os (.*?) like mac os/);
    this.isIos14 = ver ? Number(ver?.[1].split('_')[0]) >= 14 : false;
    let token = localStorage.getItem(AppLocalKey.loginToken);
    if (token) {
      main.api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  }
}

let main: Main = new Main();
export default main;
export class AppLocalKey {
  /// 登录 token
  static loginToken = 'loginToken';

  /// 记住登录账号
  static loginUsername = 'loginUsername';

  /// 显示收藏弹窗
  static showFavoriteDialog = 'showFavoriteDialog';

  /** 历史登录手机号 */
  static historyPhone = 'historyPhone';
}
