import { QRCodeCanvas } from 'qrcode.react';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { apiGetWeChatSignature } from '../../api';
import AppBar from '../../components/appBar';
import { copyToClipboard, showConfirm, showToast } from '../../utils/utils';
import Page from '../../components/page';
import main from '../../main';
import MainPage from '../mainPage';

const cbili = 8;
export default function HomePageDetail() {
  const location = useLocation();
  const [data, setData] = useState<{ imgUrl?: string; title?: string; inviteUserId?: string; goodsId?: string }>({});
  const ref = useRef<HTMLCanvasElement | null | undefined>();
  const canvasWidth = rpx(355);
  const canvasHeight = rpx(510);
  const [showMask, setShowMask] = useState(false);
  console.log(MainPage, 'MainPageMainPageMainPage')
  useEffect(() => {
    // let _d = parseLocationParams(location.search);
    console.log('location.state', location.state);
    setData(location.state || {});
    wxConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function wxConfig() {
    // @ts-ignore
    let wx = window.wx;
    let res = await apiGetWeChatSignature(
      main.isIos14 ? main.enterUrl : window.location.href.split('#')[0],
      main.appId
    );
    let cdata = res.data;

    if (cdata.code === 200) {
      let _data = cdata.data;
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: main.appId, // 必填，公众号的唯一标识
        timestamp: _data.timestamp, // 必填，生成签名的时间戳
        nonceStr: _data.nonceStr, // 必填，生成签名的随机串
        signature: _data.signature, // 必填，签名
        jsApiList: ['checkJsApi', 'updateAppMessageShareData', 'updateTimelineShareData'], // 必填，需要使用的JS接口列表
      });
    }
    wx.error((e: any) => {
      showConfirm({ title: '错误', content: e });

    });

    wx.ready(() => {
      wx.checkJsApi({
        //判断当前客户端版本是否支持指定JS接口
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'],
        success: function (res: any) {
          let _data = location.state;
          let _shareUrl = `https://wap.100861.net/goods?goodsId=${_data.goodsId ?? ''}&userId=${_data.inviteUserId ?? ''
            }`;
          // 以键值对的形式返回，可用true，不可用false。如：{"checkResult":{"scanQRCode":true},"errMsg":"checkJsApi:ok"}
          if (res.checkResult.updateAppMessageShareData !== true || res.checkResult.updateTimelineShareData !== true) {
          } else {
            wx.updateTimelineShareData({
              title: decodeURIComponent(_data.title ?? '商品'),
              link: _shareUrl,
              imgUrl: decodeURIComponent(_data.imgUrl ?? ''),
              success: function () { },
              fail: function (res: any) {
                showToast(res.errMsg);
              },
            });
            wx.updateAppMessageShareData({
              title: decodeURIComponent(_data.title ?? '商品'),
              desc: '海量商品供您选择',
              link: _shareUrl,
              imgUrl: decodeURIComponent(_data.imgUrl ?? ''),
              success: function () { },
              fail: function (res: any) {
                showToast(res.errMsg);
              },
            });
          }
        },
      });
    });
  }

  function handleShare() {
    setShowMask(!showMask);
  }

  function createImg(pic: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      let img = document.createElement('img');
      img.setAttribute('crossOrigin', 'Anonymous');
      img.onload = () => {
        resolve(img);
      };
      let _pic = pic;
      img.src = _pic;
    });
  }
  async function handleCanvasRef(v: HTMLCanvasElement) {
    ref.current = v;
    if (!v) return;
    let ctx = v.getContext('2d');
    if (!ctx) return;

    let img = await createImg(decodeURIComponent(data.imgUrl!));
    ctx.imageSmoothingEnabled = false;
    var imgbili = canvasWidth / img.width;
    // 当前画到什么位置了 高度
    let drawHeight = img.height * imgbili * cbili;
    ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvasWidth * cbili, drawHeight);
    ctx.font = `bold ${(rpx(16) * cbili).toFixed(0)}px sans-serif`;
    ctx.fillStyle = '#333';
    let lineArr = [];
    let t = decodeURIComponent(data.title!);
    if (t.length > 15) {
      lineArr.push(t.substring(0, 15));

      if (t.length > 30) {
        lineArr.push(t.substring(15, 28) + '...');
      } else {
        lineArr.push(t.substring(15, 30));
      }
    } else {
      lineArr.push(t);
    }
    drawHeight += rpx(24) * cbili;
    for (let i = 0; i < lineArr.length; i++) {
      drawHeight = drawHeight + rpx(i * 20) * cbili;
      ctx.fillText(decodeURIComponent(lineArr[i]), rpx(14) * cbili, drawHeight);
    }

    ctx.fillStyle = '#faad14';
    ctx.fillText(
      decodeURIComponent('立即领取'),
      rpx(280) * cbili,
      rpx(lineArr.length > 1 ? 34 : 24) * cbili + img.height * imgbili * cbili
    );
    drawHeight += rpx(20) * cbili;
    let qrcode = document.getElementById('qrcode') as HTMLCanvasElement;
    if (qrcode) {
      let qrimg = qrcode.toDataURL('image/png');
      let qrimgEl = await createImg(qrimg);
      ctx.imageSmoothingEnabled = false;
      let qrWidth = 80;
      ctx.drawImage(
        qrimgEl,
        0,
        0,
        qrimgEl.width,
        qrimgEl.height,
        rpx(14) * cbili,
        drawHeight,
        rpx(qrWidth) * cbili,
        rpx(qrWidth) * cbili
      );
      ctx.fillStyle = '#333';
      ctx.font = `blod ${(rpx(14) * cbili).toFixed(0)}px sans-serif`;

      drawHeight += rpx(14) * cbili;
      ctx.fillText('长按识别二维码', rpx(104) * cbili, drawHeight);
      ctx.font = `${(rpx(12) * cbili).toFixed(0)}px sans-serif`;
      drawHeight += rpx(24) * cbili;
      ctx.fillText('海量商品供您选择', rpx(104) * cbili, drawHeight);
      ctx.fillStyle = '#999999';
      ctx.font = `${(rpx(12) * cbili).toFixed(0)}px sans-serif`;
      drawHeight += rpx(40) * cbili;
      ctx.fillText('支持当面扫', rpx(104) * cbili, drawHeight);

      let logoImg = await createImg('icon_logo.png');
      ctx.imageSmoothingEnabled = false;

      ctx.drawImage(
        logoImg,
        0,
        0,
        logoImg.width,
        logoImg.height,
        (canvasWidth - rpx(80)) * cbili,
        drawHeight - rpx(80) * cbili,
        rpx(60) * cbili,
        rpx(60) * cbili
      );

      ctx.fillText('网上营业厅', (canvasWidth - rpx(80)) * cbili, drawHeight);
    }
  }

  function handleSave() {
    if (ref.current) {
      showToast('正在保存...');

      ref.current.toBlob((blob) => {
        if (blob) {
          var eleLink = document.createElement('a');
          eleLink.href = window.URL.createObjectURL(blob);
          eleLink.download = 'wap.100861.net_share_' + decodeURIComponent(data.title ?? '') + '.png';
          let event = new MouseEvent('click');
          eleLink.dispatchEvent(event);
        }
      });
    } else {
      showToast('保存失败');
    }
  }

  let shareUrl = `https://wap.100861.net/goods?goodsId=${data.goodsId ?? ''}&userId=${data.inviteUserId ?? ''}`;
  return (
    <Page
      className="bg-color-gray vw-100 vh-100 overflow-hidden d-flex-c"
      appBar={<AppBar title="" className="bg-color-t0" />}
      contentClassName="flex-1 h-1 overflow-y pb-60"
    >
      <QRCodeCanvas id="qrcode" className="d-none" size={500} level="Q" value={shareUrl} />
      <div className="m-10">
        <canvas
          ref={handleCanvasRef}
          width={canvasWidth * cbili}
          height={canvasHeight * cbili}
          style={{ width: `${canvasWidth}px`, height: `${canvasHeight}px` }}
          className="bg-color-white border-radius-10 bw-100"
        />
      </div>
      <div className="font-size-16 text-center mt-20">请选择分享方式</div>
      <div className="d-flex-r mt-20 jc-center">
        {main.isWexin && (
          <div className="d-flex-c ai-center mr-20" onClick={handleShare}>
            <img className="w-40 h-40" src={'/pic/icon_share_wechat.png'} alt="" />
            <div className="mt-5">分享微信</div>
          </div>
        )}
        <div className="d-flex-c ai-center" onClick={() => copyToClipboard(shareUrl)}>
          <img className="w-40 h-40" src={'/pic/icon_share_link.png'} alt="" />
          <div className="mt-5">分享链接</div>
        </div>
        <div className="d-flex-c ai-center ml-20" onClick={handleSave}>
          <img className="w-40 h-40" src={'/pic/icon_share_save.png'} alt="" />
          <div className="mt-5">保存相册</div>
        </div>
      </div>
      {showMask && (
        <div className="pos-a l-0 t-0 z-10 vw-100 vh-100 bg-color-3-t7" onClick={() => setShowMask(false)}>
          <img className="bw-100" src={'pic_guide_share.png'} alt="" />
        </div>
      )}
    </Page>
  );
}
function rpx(t: number) {
  return (t / 375) * window.innerWidth;
}
