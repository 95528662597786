/// 运营商类型
export class OperatorType {
  /// 联通
  static lianTong = 'lt';

  /// 电信
  static dianXin = 'dx';

  /// 移动
  static yiDong = 'yd';

  /// 广电
  static guangDian = 'gd';

  static getTypeStr(type?: string) {
    if (type === OperatorType.lianTong) {
      return '中国联通';
    }
    if (type === OperatorType.yiDong) {
      return '中国移动';
    }
    if (type === OperatorType.dianXin) {
      return '中国电信';
    }
    if (type === OperatorType.guangDian) {
      return '中国广电';
    }
    return '未知';
  }
  static getTypeIcon(type?: string) {
    if (type === OperatorType.lianTong) {
      return 'icon_ct_nicom';
    }
    if (type === OperatorType.yiDong) {
      return 'icon_ct_mobile';
    }
    if (type === OperatorType.dianXin) {
      return 'icon_ct_telecom';
    }
    if (type === OperatorType.guangDian) {
      return 'icon_ct_cbn';
    }
  }
}

/// 支付类型
export class PayType {
  /// 余额支付
  static balance = 'balancePay';

  /// 官方微信支付
  static weixin = 'wechatPay';

  /// 官方支付宝支付
  static alipay = 'aliPay';
  /// 支付宝网页支付
  static aliPage = 'aliPage';

  /// 云闪付
  static unionpay = 'union_pay';

  /// HWC微信支付 拉起小程序，在小程序内拉起微信支付
  static gatherpayWechat = 'gatherPayWechat';

  /// HWC支付宝扫码支付 得到二维码内容后跳转
  static gatherpayAli = 'gatherPayAli';

  /// HWC公众号支付
  static gatherPayJsWechat = 'gatherPayJsWechat';
  /// 官方公众号支付
  static wechatJsPay = 'wechatJsPay';
  // 银联快捷
  static sdbPayFast = 'sdbPayFast';
  // 银联快捷
  static sdbPayFastWeb = 'sdbPayFastWeb';
  // 瑞生意支付 第四方 微信支付
  static gmeJsPay = 'gmeJsPay';
  static typeStr(type?: string) {
    if (type === PayType.balance) {
      return '余额支付';
    } else if (type === PayType.unionpay) {
      return '云闪付';
    } else if (
      type === PayType.weixin ||
      type === PayType.gatherpayWechat ||
      type === PayType.gatherPayJsWechat ||
      type === PayType.wechatJsPay ||
      type === PayType.gmeJsPay
    ) {
      return '微信支付';
    } else if (type === PayType.alipay || type === PayType.gatherpayAli || type === PayType.aliPage) {
      return '支付宝支付';
    } else if (type === PayType.sdbPayFast || type === PayType.sdbPayFastWeb) {
      return '银联快捷';
    }
    return '';
  }

  static typeIcon(type?: string) {
    if (type === PayType.balance) {
      return 'icon_pay_balance.png';
    } else if (type === PayType.unionpay) {
      return 'icon_pay_union.png';
    } else if (type === PayType.sdbPayFast || type === PayType.sdbPayFastWeb) {
      return 'icon_yinlian.png';
    } else if (
      type === PayType.weixin ||
      type === PayType.gatherpayWechat ||
      type === PayType.gatherPayJsWechat ||
      type === PayType.wechatJsPay ||
      type === PayType.gmeJsPay
    ) {
      return 'icon_pay_wx.png';
    } else if (type === PayType.alipay || type === PayType.gatherpayAli || type === PayType.aliPage) {
      return 'icon_pay_zfb.png';
    }
    return 'icon_pay_balance.png';
  }
}

export class PackBuyLogStatus {
  ///  : '生效中',
  static using = 'Y';

  ///  : '待生效',
  static unUse = 'N';

  /// : '已失效',
  static close = 'close';

  /// : '已退订',
  static refund = 'return';

  /// : '生效异常',
  static error = 'error';

  /// : '已销户',
  static soldout = 'soldout';

  static getTypeList() {
    return [PackBuyLogStatus.using, PackBuyLogStatus.unUse, PackBuyLogStatus.close, PackBuyLogStatus.error, PackBuyLogStatus.soldout];
  }

  static getTypeStr(type?: string) {

    const _status = {
      [PackBuyLogStatus.using]: '生效中',
      [PackBuyLogStatus.unUse]: '待生效',
      [PackBuyLogStatus.close]: '已结算',
      [PackBuyLogStatus.refund]: '已退订',
      [PackBuyLogStatus.error]: '生效异常',
      [PackBuyLogStatus.soldout]: '已销户',
    };
    return _status[type ?? ''] ?? '未知';
  }
}
// 套餐包类型
export class PackageType {
  // 基础包
  static basicpackage = 'basicpackage';
  // 加油包
  static fuelpack = 'fuelpack';
  static getTypeStr(type: string) {
    const _type = {
      [PackageType.basicpackage]: '基础包',
      [PackageType.fuelpack]: '加油包',
    };
    return _type[type ?? ''] ?? '未知';
  }
}

export class ProtocolType {
  /// 隐私协议
  static privacyAgreement = 'privacyAgreement';

  /// 用户协议
  static userAgreement = 'userAgreement';

  /// 使用协议
  static userManual = 'userManual';

  /// 注销需知
  static destroyAccount = 'destroyAccount';

  static getTypeStr(t: string) {
    switch (t) {
      case ProtocolType.privacyAgreement:
        return '隐私协议';
      case ProtocolType.userAgreement:
        return '用户协议';
      case ProtocolType.userManual:
        return '使用协议';
      case ProtocolType.destroyAccount:
        return '注销需知';
      default:
        return '';
    }
  }
}

/// 优惠券类型
export class CouponType {
  static payment = 'payment';
  static flow = 'flow';
  static getTypeStr(type?: string) {
    const _status = {
      [CouponType.payment]: '抵扣券',
      [CouponType.flow]: '流量券',
    };
    return _status[type ?? ''] ?? '未知状态';
  }
}
/// 优惠券失效类型
export class CouponExpiredType {
  static instant = 'instant';
  static fix = 'fix';
  static getTypeStr(type?: string) {
    const _status = {
      [CouponExpiredType.instant]: '领券后生效',
      [CouponExpiredType.fix]: '固定时间生效',
    };
    return _status[type ?? ''] ?? '未知类型';
  }
}
/// 优惠券状态
export class CouponStatus {
  static unuse = 'N';
  static used = 'Y';
  static expired = 'expired';
  static getTypeStr(type?: string) {
    const _status = {
      [CouponStatus.unuse]: '未使用',
      [CouponStatus.used]: '已使用',
      [CouponStatus.expired]: '已失效',
    };
    return _status[type ?? ''] ?? '未知状态';
  }
}

/// 投诉建议反馈类型
export class ComplaintWorkerType {
  static pay = 'pay';
  static packOrder = 'packOrder';
  static active = 'active';
  static speed = 'speed';
  static real = 'real';
  static service = 'service';
  static getTypeStr(type?: string) {
    var _type = {
      [ComplaintWorkerType.pay]: '充值问题',
      [ComplaintWorkerType.packOrder]: '套餐订单问题',
      [ComplaintWorkerType.active]: '激活问题',
      [ComplaintWorkerType.speed]: '网速问题',
      [ComplaintWorkerType.real]: '实名问题',
      [ComplaintWorkerType.service]: '客服服务问题',
    };
    return _type[type ?? ''] ?? '未知类型';
  }

  static getList() {
    return [
      ComplaintWorkerType.pay,
      ComplaintWorkerType.packOrder,
      ComplaintWorkerType.active,
      ComplaintWorkerType.speed,
      ComplaintWorkerType.real,
      ComplaintWorkerType.service,
    ];
  }
}

/// 投诉建议状态
export class ComplaintStatus {
  static wait = 'wait';
  static process = 'process';
  static completed = 'completed';
  static cancel = 'cancel';
  static getTypeStr(type?: string) {
    var _status = {
      [ComplaintStatus.wait]: '待处理',
      [ComplaintStatus.process]: '处理中',
      [ComplaintStatus.completed]: '已完成',
      [ComplaintStatus.cancel]: '已取消',
    };
    return _status[type ?? ''] ?? '未知状态';
  }
}
// 充值类型
export class RechargeType {
  /// 首充
  static first = 'first';

  /// 直充
  static direct = 'direct';

  /// 续充
  static renewal = 'renewal';
}

/**
 * 默认支付方式
 */
export class DefaultPayType {
  static balance = 'balance';
  static wechat = 'wechat';
  static all = 'all';
}

/**
 * 支付订单状态
 */
export class PayOrderStatus {
  static all = 'all';
  static wait = 'wait';
  static success = 'success';
  static close = 'close';
  static partialRefund = 'partial_refund';
  static getList() {
    return [PayOrderStatus.all, PayOrderStatus.success, PayOrderStatus.close];
  }
  static getStatusStr(status: string) {
    switch (status) {
      case PayOrderStatus.all:
        return '全部';
      case PayOrderStatus.wait:
        return '待支付';
      case PayOrderStatus.success:
        return '支付成功';
      case PayOrderStatus.close:
        return '已关闭';
      case PayOrderStatus.partialRefund:
        return '有退款';
      default:
        return '未知';
    }
  }
  static getStatusColor(status: string) {
    switch (status) {
      case PayOrderStatus.wait:
        return 'origin';
      case PayOrderStatus.success:
        return 'green';
      case PayOrderStatus.close:
        return '9';
      case PayOrderStatus.partialRefund:
        return 'red';
      default:
        return 'p';
    }
  }
}
