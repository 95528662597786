import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  apiGetAvailableCouponList,
  apiGetBalance,
  apiGetPayTypeConfigList,
  apiGetSecondActivityList,
  apiGetUserInfo,
  apiPreOrder,
  apiUnifiedPay,
} from '../../api';
import AppBar from '../../components/appBar';
import Button from '../../components/button';
import CalcCount from '../../components/calcCount';
import Page from '../../components/page';
import RadioBox from '../../components/radioBox';
import main from '../../main';
import { DefaultPayType, PackageType, PayType } from '../../utils/dict';
import showPayPage from '../../utils/pay';
import { LoadingMask, formatNumber, modelParsePrice, showBottom, showConfirm, showToast } from '../../utils/utils';
import SelectCouponDialog from './selectCouponDialog';
import Switch from 'react-switch';
import { waitOrderDetect, WaitOrderSense } from '@/utils/waitOrderDetect';

interface OrderInfo {
  cardPackDetailId: string;
  couponPrice: number;
  emId: string;
  endEffectTime: string;
  isUnion: boolean;
  name: string;
  orderPrice: number;
  packDetailContent: string;
  realPrice: number;
  salePrice: number;
  startEffectTime: string;
  cardProductActivityEquityPackJson?: {
    img: string;
    price: number;
    tips: string;
  };
  activityCardPackDetail?: {
    salePrice: number;
    activityNumber: number;
    name: string;
  };
}

export default function PackOrderConfirmPage() {
  const navigate = useNavigate();
  const [wxReady, setWxReady] = useState(false);
  // 可选套餐月份数量 从emPack内取出
  const [numberLevel, setNumberLevel] = useState<number[]>([]);
  // 订单数量
  const [count, setCount] = useState(1);
  // 上个页面已选套餐和（本月或下月）
  const { emPack: _emPack, dateType: _dateType }: { emPack: EmPack; dateType: string } = useLocation().state ?? {};
  const [emPack, setEmPack] = useState(_emPack);
  const [dateType, setDateType] = useState(_dateType);
  // 预存列表 如果没有预存会返回首充列表
  const [preMoneyList, setPreMoneyList] = useState<Premoney[]>([]);
  // 已选预存
  const [preMoney, setPreMoney] = useState<Premoney>();
  // 预存列表是否已加载完成
  const [preMoneyListLoaded, setPreMoneyListLoaded] = useState(false);
  const [defaultPayType, setDefaultPayType] = useState(DefaultPayType.all);
  // 设备余额
  const [balance, setBalance] = useState(modelParsePrice(main.currentEm?.cardDetailWalletVo?.availableAmount || 0));
  // 是否有操作密码
  // const [ifPwd, setIfPwd] = useState(false);
  // 余额和操作密码是否加载完成
  const [balanceLoaded, setBalanceLoaded] = useState(false);
  // 可用优惠券列表
  const [couponList, setCouponList] = useState<Coupon[]>([]);
  // 已选优惠券ID列表
  const [coupons, setCoupons] = useState<string[]>([]);
  // 优惠券列表是否加载完成
  const [couponListLoaded, setCouponListLoaded] = useState(false);
  // 充值优惠信息
  // const [payReduction, setPayReduction] = useState<PayReduction>({});
  // 计算后得到的是否余额支付可用
  const [canBalancePay, setCanBalancePay] = useState(false);
  // 支付方式列表
  const [payConfigList, setPayConfigList] = useState<PayTypeConfig[]>([]);
  // 支付方式列表是否加载完成
  const [payConfigListLoaded, setPayConfigListLoaded] = useState(false);
  // 已选支付方式
  const [payType, setPayType] = useState(PayType.balance);
  // 使用组合支付
  const [isUnion, setIsUnion] = useState(false);
  // 选择权益包
  const [selectEquity, setSelectEquity] = useState(false);
  // 订单信息
  const [orderInfo, setOrderInfo] = useState<OrderInfo>();
  // 停留或后退计时
  const backRef = useRef<{ timer?: NodeJS.Timeout; time: number; isShow: boolean }>({ time: 0, isShow: false });

  useEffect(() => {
    preLoad();
    let _backRef = backRef.current;
    // @ts-ignore
    if (typeof WeixinJSBridge === 'undefined') {
      if (document.addEventListener) {
        document.addEventListener('WeixinJSBridgeReady', () => setWxReady(true), false);
        // @ts-ignore
      } else if (document.attachEvent) {
        // @ts-ignore
        document.attachEvent('WeixinJSBridgeReady', () => setWxReady(true));
      }
    } else {
      setWxReady(true);
    }
    if (!!emPack.numberLevel) {
      let list: number[] = emPack.numberLevel!.split(',').map((i) => parseInt(i));
      if (list.length) {
        setNumberLevel(list);
        setCount(list[0]);
      }
    }
    startBackTimer();
    return () => {
      if (_backRef.timer) {
        clearInterval(_backRef.timer);
      }
    };
  }, []); // eslint-disable-line

  function startBackTimer() {
    if (!backRef.current.timer) {
      backRef.current.timer = setInterval(() => {
        backRef.current.time++;

        if (backRef.current.time >= 60) {
          backRef.current.isShow = true;
          handleLongTimeDialog();
        }
        if (backRef.current.isShow) {
          clearInterval(backRef.current.timer);
        }
      }, 1000);
    }
  }

  async function handleBack() {
    if (backRef.current.isShow) {
      navigate(-1);
    } else {
      let res = await showConfirm({
        title: '提示',
        content: '您是否存在疑问，需要联系客服？',
        cancelBtnText: '返回',
        confirmBtnText: '联系客服',
      });
      if (res === 1) {
        navigate('/customer');
      } else {
        navigate(-1);
      }
    }
  }

  async function handleLongTimeDialog() {
    let res = await showConfirm({
      title: '提示',
      content: '您在该界面停留时间过久，是否存在疑问？',
      hideCancelBtn: true,
      confirmBtnText: '联系客服',
    });
    if (res === 1) {
      navigate('/customer');
    }
  }

  useEffect(() => {
    if (balanceLoaded && preMoneyListLoaded && couponListLoaded) {
      preOrder();
    }
  }, [count, coupons]); // eslint-disable-line
  /**
   *  预加载
   */
  async function preLoad() {
    if (!emPack || !dateType || !main.currentEm?.id) {
      navigate('/home', { replace: true });
      return;
    }
    setEmPack(emPack);
    setDateType(dateType);
    LoadingMask.show('正在处理...');
    await loadPreMoney();
    await loadBalance();
    // await loadPayConfigReduction();
    await loadPayConfigList();
    await loadCouponList();
    LoadingMask.hide();
  }
  /**
   *  获取预存金额
   */
  async function loadPreMoney() {
    let _list: Premoney[] = [];

    try {
      let res = await apiGetSecondActivityList(main.currentEm?.id!, emPack!.id!);
      let data = res.data;
      if (data.code === 200) {
        setPreMoneyListLoaded(true);
        if (data.data) {
          data.data.forEach((i: any) => {
            _list.push({
              id: i.id,
              amount: parseFloat(i.amount ?? 0),
              payAmount: parseFloat(i.payAmount ?? 0),
              forceActivity: i.forceActivity === 'Y',
            });
          });
        }
      } else {
        setPreMoneyListLoaded(false);
      }
    } catch (e: any) {
      showToast(e.message || '获取订单信息错误');

      setPreMoneyListLoaded(false);
    }
    setPreMoneyList(_list);
  }
  /**
   * 获取余额
   */
  async function loadBalance() {
    try {
      var res = await apiGetBalance(main.currentEm?.id!);
      var data = res.data;
      if (data.code === 200) {
        // var _data = data['data'];
        let balance = modelParsePrice(data.data.availableAmount);
        // let ifPwd = _data['isPayPassword'] === true;
        setBalance(balance);
        // setIfPwd(ifPwd);
        setBalanceLoaded(true);
        setDefaultPayType(data.data.payType || DefaultPayType.all);
        if (data.data.payType && data.data.payType !== 'all') {
          if (data.data.payType === DefaultPayType.balance) {
            setPayType(PayType.balance);
          } else if (data.data.payType === DefaultPayType.wechat) {
            setPayType(PayType.wechatJsPay);
          }
        }
      } else {
        setBalanceLoaded(false);
      }
    } catch (e) {
      setBalanceLoaded(false);
    }
  }

  /**
   *  获取可用优惠券列表
   * @param count 订单数量
   */
  async function loadCouponList(_count?: number) {
    try {
      var res = await apiGetAvailableCouponList(main.currentEm?.id!, emPack!.id!, _count ?? count);
      var data = res.data;

      if (data.code === 200) {
        setCouponList(data.data);
        setCoupons(data.data.length ? [data.data[0].id] : []);
        setCouponListLoaded(true);
      } else {
        setCouponListLoaded(false);
      }
    } catch (e) {
      setCouponListLoaded(false);
    }
  }

  // /**
  //  * 获取支付优惠
  //  */
  // async function loadPayConfigReduction() {
  //   try {
  //     let res = await apiGetPayConfigReduction(main.currentEm?.id!);
  //     let data = res.data;
  //     if (data.code === 200) {
  //       let _map: any = {};
  //       (data.data ?? []).forEach((i: any) => (_map[i.payType] = i));
  //       setPayReduction(_map);
  //     }
  //   } catch (e) {}
  // }

  /**
   * 获取支付配置列表
   */
  async function loadPayConfigList() {
    try {
      let res = await apiGetPayTypeConfigList();
      let data = res.data;
      if (data.code === 200) {
        let list: PayTypeConfig[] = data.data ?? [];
        setPayConfigList(list);
        setPayConfigListLoaded(true);
        let payTypes = list.map((i: PayTypeConfig) => i.payType!);
        if (payTypes.length && !payTypes.includes(payType)) {
          setPayType(payTypes[0]);
        }
      }
    } catch (e) {}
  }

  /**
   *
   * 选择优惠券
   */
  async function handleSelectCoupon() {
    if (!couponList.length) {
      return showToast('暂无可用优惠券');
    }
    let res = await showBottom((onOk) => <SelectCouponDialog onOk={onOk} list={couponList} selects={coupons} />);
    if (!res) return;
    if (res.sort().join('') !== coupons.sort().join('')) {
      setCoupons(res);
    }
  }

  /**
   *  预下单
   *
   */
  async function preOrder() {
    LoadingMask.show('正在生成预览...');
    try {
      var res = await apiPreOrder(emPack!.id!, main.currentEm!.id!, coupons, dateType, count);
      let data = res.data;
      if (data.code !== 200) {
        return showToast(data.msg || '接口错误');
      }
      let _orderInfo: OrderInfo = data.data;
      _orderInfo.salePrice = modelParsePrice(_orderInfo.salePrice);
      _orderInfo.orderPrice = modelParsePrice(_orderInfo.orderPrice);
      _orderInfo.realPrice = modelParsePrice(_orderInfo.realPrice);
      _orderInfo.couponPrice = modelParsePrice(_orderInfo.couponPrice);
      let _canBalancePay = balance >= (_orderInfo.orderPrice ?? 0);
      if (!!_orderInfo.cardProductActivityEquityPackJson) {
        _orderInfo.cardProductActivityEquityPackJson!.price = modelParsePrice(
          _orderInfo.cardProductActivityEquityPackJson!.price
        );
        setSelectEquity(true);
        _canBalancePay =
          balance >= (_orderInfo.realPrice ?? 0) + (_orderInfo.cardProductActivityEquityPackJson!.price ?? 0);
      }
      if (!!_orderInfo.activityCardPackDetail) {
        _orderInfo.activityCardPackDetail!.salePrice = modelParsePrice(_orderInfo.activityCardPackDetail!.salePrice);
        _orderInfo.activityCardPackDetail!.activityNumber = modelParsePrice(
          _orderInfo.activityCardPackDetail!.activityNumber
        );
      }

      let _payType = payType;
      let _preMoney: Premoney | undefined = undefined;

      let forceActivity = preMoneyList.some((i) => i.forceActivity === true);
      if (!_canBalancePay && _payType === PayType.balance) {
        _payType = payConfigList.find((i) => i.payType !== PayType.balance)?.payType!;
        if (preMoneyList?.length && forceActivity) {
          _preMoney = preMoneyList[0];
        }
      }

      if (preMoneyList?.length && (_preMoney?.payAmount ?? 0) < (_orderInfo.orderPrice ?? 0)) {
        for (let i of preMoneyList) {
          if (i.payAmount >= (_orderInfo.orderPrice ?? 0)) {
            _preMoney = i;
            break;
          }
        }
      }
      if ((_preMoney?.payAmount ?? 0) < (_orderInfo.orderPrice ?? 0)) {
        _preMoney = undefined;
      }
      if (!preMoney && !forceActivity) {
        _preMoney = undefined;
      }

      setOrderInfo(_orderInfo);
      setCanBalancePay(_canBalancePay);
      if (defaultPayType === DefaultPayType.all) {
        setPayType(_payType);
      }
      setPreMoney(_preMoney);
    } catch (e) {
      console.log(e);
    } finally {
      LoadingMask.hide();
    }
  }

  async function handlePay() {
    // 判断是否有待支付订单，如果有就关闭订单
    const isPass = await waitOrderDetect({ sense: WaitOrderSense.Pay });
    if (!isPass) return;

    if (!main.openId && !main.isDev) {
      return showToast('授权失败,请在微信内打开');
    }
    if (!balanceLoaded || !preMoneyListLoaded || !couponListLoaded || !payConfigListLoaded) {
      return showToast('获取下单数据错误，请稍后重试');
    }
    if (
      preMoneyList.length &&
      payType !== PayType.balance &&
      !!preMoney &&
      (preMoney?.payAmount ?? 0) < (orderInfo?.realPrice ?? 0)
    ) {
      return showToast('预存费用不能低于套餐金额');
    }
    if (!canBalancePay && payType === PayType.balance) {
      let res = await showConfirm({ title: '提醒', content: '余额不足，是否前往充值？', confirmBtnText: '去充值' });
      if (res) navigate('/recharge');
      return;
    }

    // 要支付的金额
    let price = (payType === PayType.balance ? orderInfo?.realPrice : preMoney?.payAmount ?? orderInfo?.realPrice) ?? 0;
    if (selectEquity) {
      price = price + orderInfo!.cardProductActivityEquityPackJson!.price;
    }
    let confirmRes = await showConfirm({
      title: '提示',
      content: (
        <div className="d-flex-c ai-start font-size-12">
          <div>
            本次消费用<span className="text-color-p">{PayType.typeStr(payType)}</span>支付:{price}元
          </div>
          {!!selectEquity && (
            <div>
              <span className="text-color-9">权益费:</span>
              <span className="text-color-3">{orderInfo!.cardProductActivityEquityPackJson!.price}元</span>
            </div>
          )}
          <div className="text-left">
            <span className="text-color-9">订单名称:</span>
            <span className="text-color-3">{orderInfo?.name}</span>
          </div>
          <div>
            <span className="text-color-9">订单类型:</span>
            <span className="text-color-3">
              {emPack.packageType === PackageType.basicpackage ? '基础包' : '加油包'}
            </span>
          </div>
          <div>
            <span className="text-color-9">套餐消费:</span>
            <span className="text-color-3">{orderInfo?.realPrice}元</span>
          </div>
          <div>套餐有效期:</div>
          {dateType === 'next' && <div className="text-color-red">请注意,您购买的是下期套餐</div>}
          <div className={`text-color-3 ${dateType === 'next' ? 'text-color-red' : ''}`}>
            {dayjs(orderInfo?.startEffectTime).format('YYYY-MM-DD')}至
            {dayjs(orderInfo?.endEffectTime).format('YYYY-MM-DD')}
          </div>
        </div>
      ),
    });
    if (!confirmRes) return;

    // let pwd: string = '';
    // if (payType === PayType.balance) {
    //   if (!ifPwd) {
    //     let res = await showConfirm({
    //       title: '提示',
    //       content: '请设置操作密码',
    //       hideCancelBtn: true,
    //       confirmBtnText: '去设置',
    //     });
    //     if (res !== 1) return;
    //     navigate('/set-password');
    //     return;
    //   }
    //   let res = await showBottom((onOk) => <PayPasswordDialog onOk={onOk} price={orderInfo.realPrice} />);
    //   if (!res) return;
    //   pwd = res;
    // }

    try {
      LoadingMask.show('正在提交...');

      var body: any = {
        emId: main.currentEm?.id,
        cardPackDetailId: emPack!.id!,
        couponCodeIds: coupons,
        dateType: dateType,
        payType: price > 0 ? payType : PayType.balance,
        openId: main.openId,
        appId: main.appId,
        number: count,
        isUnion,
        isEquity: selectEquity,
      };
      if (payType === PayType.balance) {
        // body.payPassword = pwd;
      } else {
        body.cardPayDetailId = preMoney?.id;
      }

      let res = await apiUnifiedPay(body);
      let data = res.data;
      if (data.code === 200) {
        if (payType === PayType.balance || price === 0) {
          paySuccess();
        } else if (payType === PayType.aliPage) {
          //支付宝支付
          let _data = data.data;
          let payRes = await showPayPage(_data, payType);

          if (payRes.code === 300) {
            if (payRes.url) {
              LoadingMask.hide();
              window.location.href = payRes.url;
              // await showConfirm({
              //   title: '提示',
              //   content: '是否支付完成?',
              //   confirmBtnText: '支付完成',
              //   cancelBtnText: '未完成',
              // });
              navigate('/home', { replace: true });
            }
            showToast(payRes.msg);
          } else if (payRes.code === 500) {
            showToast(payRes.msg);
          } else {
            showToast('数据还在准备,请稍后重试');
          }
        } else {
          if (wxReady) {
            let _data = data.data;
            let payRes = await showPayPage(_data, payType);

            if (payRes.code === 200) {
              paySuccess();
            } else if (payRes.code === 300) {
              if (payRes.url) {
                LoadingMask.hide();
                navigate('/customer', { state: { url: payRes.url, title: '银联支付', pay: true } });
              }
              showToast(payRes.msg);
            } else if (payRes.code === 500) {
              showToast(payRes.msg);
            }
          } else {
            showToast('数据还在准备,请稍后重试');
          }
        }
      } else {
        showToast(data.msg ?? '下单失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '下单失败');
    } finally {
      LoadingMask.hide();
    }
  }

  async function paySuccess() {
    try {
      let res = await apiGetUserInfo(main.loginKey!);
      let data = res.data;

      if (data.code === 200) {
        main.user = data.data.user;
      }
    } catch (e: any) {
      console.error(e);
    } finally {
      navigate('/recharge-success', {
        replace: true,
        state: {
          name: orderInfo?.name,
          type: PackageType.basicpackage ? '基础包' : '加油包',
          startTime: dayjs(orderInfo?.startEffectTime).format('YYYY-MM-DD'),
          endTime: dayjs(orderInfo?.endEffectTime).format('YYYY-MM-DD'),
        },
      });
    }
  }

  function handleLookTips() {
    showConfirm({
      title: '购买需知',
      content: orderInfo!.cardProductActivityEquityPackJson!.tips,
      hideCancelBtn: true,
      confirmBtnText: '关闭',
    });
  }

  async function handleSelectEquity() {
    if (selectEquity) {
      let res = await showConfirm({
        title: '权益包',
        content: (
          <div className="text-left bw-100">
            <div>是否取消权益包？</div>
            <div>取消权益包您将失去权益包提供的各种权益</div>
          </div>
        ),
        confirmBtnText: '继续购买',
        cancelBtnText: '残忍拒绝',
        maskClose: false,
        cancelClose: false,
      });
      if (res === 1) return;
    }
    let resSelect = !selectEquity;
    setSelectEquity(resSelect);

    // 计算是否可以余额支付
    let _canBalancePay = balance >= (orderInfo?.realPrice ?? 0);
    if (resSelect) {
      _canBalancePay =
        balance >= (orderInfo?.realPrice ?? 0) + (orderInfo?.cardProductActivityEquityPackJson!.price ?? 0);
    }
    setCanBalancePay(_canBalancePay);
    // 如果可以余额支付 设置为余额支付
    if (_canBalancePay) {
      setPayType(PayType.balance);
    } else if (payType === PayType.balance) {
      // 如果不可余额支付 当前又是余额支付 则设置为其他支付方式
      let _payType = payConfigList.find((i) => i.payType !== PayType.balance)?.payType!;
      if (_payType) {
        setPayType(_payType);
      }
    }
    // 切换选中权益包状态必定关闭组合支付
    setIsUnion(false);
  }

  function renderEquity() {
    if (!orderInfo?.cardProductActivityEquityPackJson) {
      return null;
    }
    let _data = orderInfo!.cardProductActivityEquityPackJson!;
    return (
      <div className="pos-r">
        <img src={_data.img} className="pos-r z-0 bw-100" alt="" onClick={handleSelectEquity} />
        <div className="pos-a t-10 r-10 text-right">
          <RadioBox value={selectEquity} onChange={handleSelectEquity} />
          <div className="text-color-9 font-size-12 mt-5" onClick={handleLookTips}>
            购买须知
          </div>
        </div>
      </div>
    );
  }

  /**
   * 已选套餐包
   * @returns view
   */
  function renderSelectPack() {
    return (
      <div className="mt-10 bg-white pt-10 pb-10 pl-20 pr-20 d-flex-r ai-center">
        <div className="flex-1">
          <div>订单名称：{emPack?.name}</div>
          <div className="mt-10">
            订单类型：{emPack?.packageType === PackageType.basicpackage ? '基础包' : '加油包'}
          </div>
        </div>
        <div className="font-size-18 font-widget-600">￥{emPack?.salePrice}</div>
      </div>
    );
  }
  /**
   * 优惠券
   * @returns view
   */
  function renderCoupon() {
    let selectCoupons: Coupon[] = [];
    if (coupons?.length) {
      selectCoupons = couponList.filter((i) => coupons.includes(i.id!));
    }

    var couponTips = '';

    if (!couponList?.length) {
      couponTips = '暂无可用优惠券';
    } else if (!coupons?.length) {
      couponTips = '有可用优惠券';
    } else if (selectCoupons.length === 1) {
      couponTips = selectCoupons[0].couponName!;
    } else if (selectCoupons.length > 1) {
      couponTips = `组合优惠 - ${orderInfo?.couponPrice ?? 0}`;
    }
    return (
      <div className="mt-10 bg-white pt-10 pb-10 pl-20 pr-20  d-flex-r ai-center" onClick={handleSelectCoupon}>
        <div className="flex-1">优惠券</div>
        <div className={`${couponList.length > 0 ? 'text-color-red' : 'text-color-9'} font-size-12 mr-10`}>
          {couponTips}
        </div>
        <img className="icon-12" src="/pic/icon_arrow_right.png" alt="" />
      </div>
    );
  }

  /**
   * 购买数量
   * @returns view
   */
  function renderCount() {
    return (
      <div className="mt-10 bg-white pt-10 pb-10 pl-20 pr-20">
        <div className="d-flex-r">
          <div className="flex-1">购买数量</div>
          <CalcCount
            min={1}
            number={count}
            onChange={(value) => setCount(value)}
            max={!!emPack?.buyLimit ? emPack?.buyLimit : 99}
          />
        </div>
        <div className="font-size-12 text-color-9 mt-10">
          套餐有效期:{orderInfo?.startEffectTime ? dayjs(orderInfo.startEffectTime).format('YYYY-MM-DD') : '-'}至
          {orderInfo?.endEffectTime ? dayjs(orderInfo.endEffectTime).format('YYYY-MM-DD') : '-'}
        </div>
      </div>
    );
  }
  /**
   * 赠送套餐
   * @returns view
   */
  function sendPackage() {
    return (
      <div className="mt-10 bg-white pt-10 pb-10 pl-20 pr-20 ">
        <div className="font-size-14">赠送套餐</div>
        <div className="d-flex-r ai-center">
          <div className="flex-1 d-flex-c font-size-12">
            <div className="mt-10 pl-10">套餐名称：{orderInfo?.activityCardPackDetail?.name}</div>
            <div className="mt-10 pl-10">赠送数量：{orderInfo?.activityCardPackDetail?.activityNumber}</div>
          </div>
          <div className="text-right">
            <div className="font-widget-600 font-size-18 text-color-9">
              <span className="font-size-14">现价</span>:0
            </div>
            <div className="font-size-12  text-deleteline">¥{orderInfo?.activityCardPackDetail?.salePrice}</div>
          </div>
        </div>
      </div>
    );
  }

  /**
   * 可选套餐数量
   */
  function renderNumberLevel() {
    return (
      <div className="mt-10 bg-white pt-10 pb-10 pl-20 pr-20">
        <div>购买数量</div>
        <div className="d-flex-r mt-10 flex-wrap">
          {numberLevel.map((item) => {
            let active = count === item;
            return (
              <div
                key={item}
                className={`w-100 h-40 line-height-40 d-flex-r ai-center jc-center mb-10 mr-10 border border-radius-8 ${
                  active ? 'border-color-p text-color-p' : 'border-color-gray'
                }`}
                onClick={() => setCount(item)}
              >
                <div className="d-flex-r ai-baseline">
                  <span className="font-size-20">{item}</span>
                  <span className="font-size-12">个月</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  /**
   * 订单明细
   * @returns view
   */
  function renderOrderInfo() {
    return (
      <div className="mt-10 bg-white pt-10 pb-10 pl-20 pr-20">
        <div>订单明细</div>
        <div className="d-flex-r ai-center jc-between pl-20 mt-20">
          <div className="font-size-12">商品总价</div>
          <div>￥{orderInfo?.salePrice}</div>
        </div>
        <div className="d-flex-r ai-center jc-between pl-20 mt-20">
          <div className="font-size-12">优惠金额</div>
          <div>￥{orderInfo?.couponPrice}</div>
        </div>
        <div className="d-flex-r ai-center jc-between pl-20 mt-20">
          <div className="font-size-12">商品合计</div>
          <div>￥{orderInfo?.realPrice}</div>
        </div>
      </div>
    );
  }

  /**
   * 预存
   */
  function renderPreMoney() {
    if (!preMoneyList?.length) {
      return <div></div>;
    }
    let forceActivity = preMoneyList.some((i) => i.forceActivity === true);
    return (
      <div className="mt-10 bg-white pt-10 pb-10 pl-20 pr-20">
        <div>
          充值金额<span className="font-size-12 text-color-9 ml-10">*套餐费用从充值金额扣除</span>
        </div>
        <div className="d-flex-r mt-10 flex-wrap">
          {preMoneyList.map((item) => {
            var orderPrice = orderInfo?.orderPrice || 0;
            let disabled = payType === PayType.balance || item.payAmount < orderPrice;
            let active = payType !== PayType.balance && preMoney && item.id === preMoney?.id;
            return (
              <div
                key={item.id}
                className={`pos-r w-100 h-70 d-flex-c ai-center jc-center text-center mb-10 mr-10 border border-radius-8 ${
                  disabled
                    ? 'bg-gray border-color-gray'
                    : active
                    ? 'border-color-p bg-primary-t1'
                    : 'bg-primary-t1 border-color-p-t1'
                }`}
                onClick={() => {
                  if (disabled) {
                    if (!forceActivity) {
                      setPreMoney(undefined);
                    }
                    return;
                  }
                  if (forceActivity) {
                    setPreMoney(item);
                  } else {
                    if (preMoney?.id === item.id) {
                      setPreMoney(undefined);
                    } else {
                      setPreMoney(item);
                    }
                  }
                }}
              >
                <div className="font-size-12">
                  <span className="font-size-18 font-widget-600">{item.amount}</span>元
                </div>
                <div className="font-size-12 text-color-p mt-5">售价{item.payAmount}元</div>
                {item.amount > item.payAmount && (
                  <img className="pos-a z-10 r-0 t-0 w-36" src="/pic/icon_coupon_flag.png" alt="" />
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  /**
   * 支付方式
   */
  function renderPayType() {
    return (
      <div className="mt-10 bg-white pt-10 pb-10 pl-20 pr-20">
        <div>请选择支付方式</div>
        {payConfigList.map((item, idx) => {
          let subLabel = item.payType === PayType.balance ? `(￥${balance})` : '';

          if (item.slogan && item.payType !== PayType.balance) {
            subLabel = item.slogan;
          }
          // 是否使用开关
          let useSwitch = false;
          // 本条支付是余额支付 可以组合支付 又没有首充续充
          if (item.payType === PayType.balance && orderInfo?.isUnion && !preMoneyList?.length) {
            // 有余额但不够支付 单选框变成开关
            if (balance > 0 && balance < orderInfo.realPrice) {
              useSwitch = true;
            } else if (
              balance > 0 &&
              selectEquity &&
              balance < orderInfo.realPrice + orderInfo.cardProductActivityEquityPackJson!.price
            ) {
              useSwitch = true;
            }
          }
          return (
            <div
              key={idx}
              className="pl-20 mt-20 d-flex-r ai-center"
              onClick={() => {
                if (useSwitch) {
                  setIsUnion(!isUnion);
                } else {
                  setPayType(item.payType!);
                }
              }}
            >
              <img className="icon-20 mr-10" src={`/pic/${PayType.typeIcon(item.payType)}`} alt="" />
              <span className="font-size-12">{item.name || PayType.typeStr(item.payType)}</span>
              <span
                className={`ml-10 font-size-12 text-color-${item.payType === PayType.balance ? '9' : 'red'} flex-1`}
              >
                {subLabel}
              </span>
              {useSwitch && (
                <span className="font-size-12 text-color-9 mr-2">{isUnion ? `支付¥${balance}` : '需组合支付'}</span>
              )}
              {isUnion && item.payType === payType && (
                <span className="font-size-12 text-color-9 mr-2">
                  支付¥
                  {formatNumber(
                    (orderInfo?.realPrice ?? 0) +
                      (selectEquity ? orderInfo?.cardProductActivityEquityPackJson?.price ?? 0 : 0) -
                      balance
                  )}
                </span>
              )}
              {useSwitch ? (
                <Switch
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onColor="#6571ff"
                  offColor="#ccc"
                  width={40}
                  height={24}
                  checked={isUnion}
                  onChange={() => setIsUnion(!isUnion)}
                />
              ) : (
                <RadioBox
                  value={item.payType === payType}
                  // disabled={item.payType === PayType.balance && !canBalancePay && !isUnion}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }

  let price = (payType === PayType.balance ? orderInfo?.realPrice : preMoney?.payAmount ?? orderInfo?.realPrice) ?? 0;

  if (selectEquity) {
    price = price + orderInfo!.cardProductActivityEquityPackJson!.price;
  }
  if (isUnion) {
    price -= balance;
  }
  return (
    <Page
      className="d-flex-c bg-gray"
      contentClassName="flex-1 h-1 d-flex-c"
      appBar={<AppBar title="支付订单" onBack={handleBack}></AppBar>}
    >
      <div className="flex-1 overflow-y">
        {renderEquity()}
        {renderSelectPack()}
        {!!orderInfo?.activityCardPackDetail && sendPackage()}
        {renderCoupon()}
        {!!numberLevel.length ? renderNumberLevel() : renderCount()}
        {renderOrderInfo()}
        {renderPreMoney()}
        {renderPayType()}
      </div>
      <div className="pt-10 pb-10 pl-20 pr-20 bg-white">
        <Button type="primary" size="md" long onClick={handlePay}>
          确认支付￥{formatNumber(price)}
          {!!selectEquity && <span className="font-size-12 ml-5 text-color-origin">(含权益费)</span>}
        </Button>
      </div>
    </Page>
  );
}
