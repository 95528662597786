import { CardProductAdvertiseType } from '@/constants';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { NoticeBar } from 'react-vant';
import {
  apiGeEmInfo,
  apiGetCardHomeCarousel,
  apiGetCardLibraryCustomer,
  apiGetCardPackDetail,
  apiGetFirstActivityList,
  apiGetProductAdverties,
  apiGetRealCount,
  apiGetgetGoodsClass,
  apiGettGoodsList,
  apiInspect,
  apiIsActive,
} from '../../api';
import Button from '../../components/button';
import Page from '../../components/page';
import TabBarChange from '../../components/tabBarChange';
import main, { AppLocalKey } from '../../main';
import { RechargeType } from '../../utils/dict';
import {
  LoadingMask,
  copyToClipboard,
  flowKTOG,
  formatNumber,
  showBottom,
  showConfirm,
  showToast,
} from '../../utils/utils';
import FavoriteDialog from './favoriteDialog';
import FirstActiveFloat from './firstActiveFloat';
import RecommendAdvDialog, { AdvImg } from './recommendAdvDialog';
import WifiInfo from './wifiInfo';
export class GoodsTagIcon {
  /// 热卖
  static hot = 'hot';

  /// 秒杀
  static secondsKill = 'seconds_kill';

  /// 火爆
  static fieryFire = 'fiery_fire';

  /// 推荐
  static push = 'push';
  static getTagIcon(tag: string): string {
    const icons = {
      [GoodsTagIcon.hot]: '/pic/icon_tag_sale.png',
      [GoodsTagIcon.secondsKill]: '/pic/icon_tag_second_kill.png',
      [GoodsTagIcon.fieryFire]: '/pic/icon_tag_hot.png',
      [GoodsTagIcon.push]: '/pic/icon_tag_push.png',
    };
    return icons[tag] ?? '';
  }
}
export default function HomePage() {
  // 定义 ceanImg 类型
  type ceanImg = {
    imgUrl: string;
    type: string;
    skipUrl: string;
  };
  const navigate = useNavigate();
  const [currentEm, setCurrentEm] = useState<CurrentEm | undefined>(main.currentEm);
  const [realCount, setRealCount] = useState<{
    realNameY?: number;
    realNameN?: number;
    all?: boolean;
    description?: string;
  }>({});
  // const [packOrderCount, setPackOrderCount] = useState(0);
  // const [payOrderCount, setPayOrderCount] = useState(0);
  // const [incomeCount, setIncomeCount] = useState(0);
  // const [couponCount, setCouponCount] = useState(0);
  const [oceanList, setoceanList] = useState<{ left: ceanImg[]; right: ceanImg[] }>({
    left: [],
    right: [],
  });

  // 是否有首充活动 有的话就悬浮按钮 并判断是否直接跳转进去
  const [hasChargeFirstActive, setHasChargeFirstActive] = useState(false);
  // 是否强制活动
  const [forceActivity, setForceActivity] = useState(false);
  // 是否自动跳转活动页面
  const [showActivity, setShowActivity] = useState(false);
  // 所有接口加载完，一般是在设备详情加载完之后，再加载各种统计和活动，最后一个接口设置加载完成
  const [loaded, setLoaded] = useState(false);
  //卡库中的客服电话
  const [serverPhone, setServerPhone] = useState('');
  /** 显示首页广告 */
  const [showRecommendAdvDialog, setShowRecommendAdvDialog] = useState<any>();
  useEffect(() => {
    loadCurrentEM();
    getOceanList(); //获取轮播图
    getShopTypes(); //获取商城-分类
  }, []); // eslint-disable-line
  async function getOceanList() {
    try {
      let res = await apiGetCardHomeCarousel();
      let data = res.data || [];
      if (res.status === 200) {
        let list = {
          left: [],
          right: [],
        };
        list.left = data
          .filter((item: any) => {
            return item.position === 'left';
          })
          .sort((a: any, b: any) => {
            return a.priority - b.priority;
          })
          .map((item: any) => {
            return {
              imgUrl: item.imgUrl,
              type: item.type,
              skipUrl: item.skipUrl,
            };
          });
        list.right = data
          .filter((item: any) => {
            return item.position === 'right';
          })
          .sort((a: any, b: any) => {
            return a.priority - b.priority;
          })
          .map((item: any) => {
            return {
              imgUrl: item.imgUrl,
              type: item.type,
              skipUrl: item.skipUrl,
            };
          });
        setoceanList(list);
      }
    } catch (e: any) {}
  }
  useEffect(() => {
    if (loaded) {
      handleCheckPageStatus();
    }
  }, [loaded]); // eslint-disable-line

  async function handleCheckPageStatus() {
    // 优先验证绑卡，如果链接内带卡号 直接去绑卡
    if (!!main.nextBindEm) {
      navigate('/add-em');
      return;
    }
    // 是否自动跳到了别的页面，是的话不检测收藏弹窗，没有跳的时候才检测
    let toSomePage = false;
    if (hasChargeFirstActive && !main.toActivity[currentEm?.id!]) {
      if (!forceActivity) {
        main.toActivity[currentEm?.id!] = true;
      }
      toSomePage = true;
      if (showActivity) {
        navigate('/activity');
      }
    } else if (
      (!main.currentEm?.packDetailId || !main.currentEm?.endEffectTime) &&
      !main.toBuyPackage[main.currentEm?.id!]
    ) {
      main.toBuyPackage[main.currentEm?.id!] = true;
      if (main.hasAdverties) return;
      let modalRes = await showConfirm({
        title: '智能检测结果',
        content: '未购买套餐，无法提供联网服务',
        hideCancelBtn: true,
        confirmBtnText: '去订购套餐',
      });
      if (modalRes) {
        toSomePage = true;
        // 没有套餐
        navigate('/select-pack');
      }
    } else if (
      !realCount.all &&
      !realCount.realNameY &&
      !main.toRealName[main.currentEm?.id!] &&
      !main.toBuyPackage[main.currentEm?.id!]
    ) {
      main.toRealName[main.currentEm?.id!] = true;
      let modalRes = await showConfirm({
        title: '智能检测结果',
        content: '设备下有未实名认证卡片，无法提供联网服务',
        hideCancelBtn: true,
        confirmBtnText: '去实名认证',
      });
      if (modalRes) {
        toSomePage = true;
        navigate('/real-name');
      }
    }

    // 如果不需要跳页面 又没有弹出过收藏弹窗
    if (!toSomePage) {
      let isOpenDialog = localStorage.getItem(AppLocalKey.showFavoriteDialog);
      if (isOpenDialog !== 'true') {
        localStorage.setItem(AppLocalKey.showFavoriteDialog, 'true');
        await showBottom((onOk) => <FavoriteDialog onOk={onOk} />);
      }
    }
  }

  // 通过设备ID检查是否参与过活动 参与过的不再跳转
  async function checkFirstActivity(id: string) {
    try {
      let res = await apiIsActive(id, RechargeType.first);
      setHasChargeFirstActive(!!res.data.data);
    } catch (e) {}
    setLoaded(true);
  }
  /// 通过设备ID获取活动信息 是否强制参与活动，优先以checkFirstActivity是否参与过活动判断
  async function loadActivity(id: string) {
    try {
      let res = await apiGetFirstActivityList(id);
      let data = res.data;
      if (data.code === 200) {
        let _data = data.data;
        setForceActivity(_data.forceActivity === 'Y');
        setShowActivity(_data.showActivity === 'Y');
      } else {
        showToast(data.msg ?? '获取活动失败,请重试');
      }
    } catch (e: any) {
      showToast(e.message ?? '获取活动失败,请重试');
    }
  }

  async function loadCurrentEM() {
    try {
      let res = await apiGeEmInfo();
      let data = res.data;

      if (data.data) {
        setCurrentEm(data.data);
        main.currentEm = data.data;
        // await loadPayOrderCount(main.currentEm?.id!);
        // await loadPackOrderCount(main.currentEm?.id!);
        // await loadIncomeCount(main.currentEm?.id!);
        // await loadCouponCount(main.currentEm?.id!);
        await loadRealCount(main.currentEm?.id!);
        await loadActivity(main.currentEm?.id!);
        await loadProductAdverties(main.currentEm?.id!);
        await loadServerPhone(main.currentEm?.emNumber);
        // 始终保持最后一个 保证 loaded
        await checkFirstActivity(main.currentEm?.id!);
      } else {
        // 没有卡
      }
    } catch (e: any) {
      // TODO: 网络错误
    }
  }

  async function loadServerPhone(emNumber?: string) {
    if (!emNumber) return;
    try {
      let res = await apiGetCardLibraryCustomer(emNumber!);
      let data = res.data;
      if (data.code === 200) {
        let _phone = (data.data ?? []).find((i: any) => !!i.serverPhone)?.serverPhone;
        if (!!_phone) {
          setServerPhone(_phone);
        }
      }
    } catch (e: any) {}
  }

  async function loadRealCount(emId: string) {
    try {
      let res = await apiGetRealCount(emId);
      let data = res.data;
      if (data.code === 200) {
        setRealCount(data.data);
      }
    } catch (e: any) {}
  }
  // async function loadPayOrderCount(emId: string) {
  //   try {
  //     let res = await apiGetPayOrderCount(emId);
  //     let data = res.data;
  //     if (data.code === 200) {
  //       setPayOrderCount(data.data);
  //     }
  //   } catch (e: any) { }
  // }

  // async function loadIncomeCount(emId: string) {
  //   try {
  //     let res = await apiGetIncomeCount(emId);
  //     let data = res.data;
  //     if (data.code === 200) {
  //       setIncomeCount(data.data ?? 0);
  //     }
  //   } catch (e: any) { }
  // }

  // async function loadPackOrderCount(emId: string) {
  //   try {
  //     let res = await apiGetPackOrderCount(emId);
  //     let data = res.data;
  //     if (data.code === 200) {
  //       setPackOrderCount(data.data ?? 0);
  //     } else {
  //     }
  //   } catch (e) { }
  // }

  // async function loadCouponCount(emId: string) {
  //   try {
  //     let res = await apiGetCouponCount(emId);
  //     let data = res.data;
  //     if (data.code === 200) {
  //       setCouponCount(data.data ?? 0);
  //     } else {
  //     }
  //   } catch (e) { }
  // }

  /**
   * 取值优先级：
   * 1. 系统参数配置的电话
   * 2. config.js配置的电话
   */
  function handleCallPhone() {
    /**
     * 取值优先级：
     * 0. 卡库
     * 1. 系统参数配置的电话
     * 2. config.js配置的电话
     */
    let tel400 = serverPhone;
    if (!tel400 && main.configList) {
      let config = main.configList.find((i) => i.configKey === main.tel400Key);
      if (config) {
        tel400 = config.configValue!;
      }
    }
    if (!tel400) {
      tel400 = main.tel400;
    }
    window.open(`tel:${tel400}`);
  }
  /**
   * 广告弹窗
   * @param id 设备ID
   */
  async function loadProductAdverties(id?: string) {
    if (!id) return;
    if (!!main.nextBindEm) {
      return;
    }
    try {
      let res = await apiGetProductAdverties({
        emId: id,
        type: CardProductAdvertiseType.Recommend,
      });
      let data = res.data;
      let cardProductAdvertise = data.data?.cardProductAdvertise;
      let imgList = cardProductAdvertise?.businessJson?.item;
      if (data.code === 200 && cardProductAdvertise?.id && !!imgList?.length) {
        main.hasAdverties = true;
        setShowRecommendAdvDialog(imgList);
        // let res = await showConfirm({
        //   title: '',
        //   maskClose: true,
        //   contentClose: 'center',
        //   content: (
        //     <div style={{ margin: '0 -5.333vw -7vw -5.333vw' }}>
        //       <img className="bw-100" src={cardProductAdvertise.imgUrl} alt="" />
        //     </div>
        //   ),
        //   hideCancelBtn: true,
        //   hideConfirmBtn: true,
        // });
        // if (res === 'center' && packDetail) {
        //   navigate('/pack-order-confirm', {
        //     state: { emPack: packDetail, dateType: 'now' },
        //   });
        // }
      }
    } catch (e) {}
  }

  // 通过广告弹窗跳转套餐购买
  async function handleToBuyEmPack(item?: AdvImg) {
    setShowRecommendAdvDialog(undefined);
    if (!item?.packDetailId) return;
    try {
      let res = await apiGetCardPackDetail(item.packDetailId);
      let data = res.data;
      if (data.code === 200) {
        let packDetail = data.data;
        navigate('/pack-order-confirm', {
          state: { emPack: packDetail, dateType: 'now' },
        });
      }
    } catch (e) {}
  }

  function handleToComplaint() {
    navigate('/complaint-index');
  }
  // 智能检测
  async function handleInspect() {
    LoadingMask.show('正在检测...');
    try {
      let res = await apiInspect(currentEm?.id!);
      let data = res.data;
      LoadingMask.hide();
      if (data.code === 200) {
        let inspect: EmInspect = data.data;
        if (!!inspect.packOrder) {
          let modalRes = await showConfirm({
            title: '智能检测结果',
            content: '未购买套餐，无法提供联网服务',
            hideCancelBtn: true,
            confirmBtnText: '去订购套餐',
          });
          if (!modalRes) return;
          navigate('/select-pack');
        } else if (!!inspect.real) {
          let modalRes = await showConfirm({
            title: '智能检测结果',
            content: '设备下无实名认证卡片，无法提供联网服务',
            hideCancelBtn: true,
            confirmBtnText: '去实名认证',
          });
          if (!modalRes) return;
          navigate('/real-name');
        } else if (!!inspect.error) {
          let modalRes = await showConfirm({
            title: '智能检测结果',
            content:
              '当前卡片异常，建议切换联网卡或联系客服！异常原因如下：号卡存在异常使用情况，个人身份信息或号卡可能被不法份子利用！',
            cancelBtnText: '联系客服',
            confirmBtnText: '切换网络',
          });
          if (!modalRes) {
            navigate('/customer');
          } else {
            navigate('/switch-network');
          }
        } else if (!!inspect.flow) {
          let modalRes = await showConfirm({
            title: '智能检测结果',
            content: '当前套餐流量已用尽，可购买套餐后使用联网服务！',
            hideCancelBtn: true,
            confirmBtnText: '去订购套餐',
          });
          if (!modalRes) return;
          navigate('/select-pack');
        } else {
          let modalRes = await showConfirm({
            title: '智能检测结果',
            content: '设备正常，若依然无法联网，请联系客服！',
            cancelBtnText: '关闭',
            confirmBtnText: '联系客服',
          });
          if (!modalRes) return;
          navigate('/customer');
        }
      } else {
        showToast(data.msg ?? '检测失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '检测失败');
    } finally {
      LoadingMask.hide();
    }
  }

  // 复购 入口配置
  let reBuyEntery: Config | undefined;
  if (main.configList) {
    reBuyEntery = main.configList.find((i) => i.configKey === main.reBuyEnteryKey);
    if (!reBuyEntery?.configValue || !reBuyEntery?.remark) {
      // 没有设置图片或跳转连接就不显示
      reBuyEntery = undefined;
    }
  }

  // // 实名情况 1 全实名 2 部分实名 3 未实名
  // let realNameTag = '1';
  // if (realCount.all) {
  //   realNameTag = '1'
  // } else if (!!realCount.realNameY) {
  //   realNameTag = '2'
  // } else {
  //   realNameTag = '3'
  // }
  //--------------------------------------
  let surplusFlow = flowKTOG(currentEm?.surplusFlow);
  //------------------------------------------

  // 是否显示不限量
  var isUnlimit = flowKTOG(currentEm?.totalFlow ?? 0) >= main.showUnlimited;
  // 运营商

  const settings = {
    dots: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // 自动滚动
    autoplaySpeed: 5000, // 每3秒滚动一次
  };
  const options = [
    {
      label: '专属客服',
      url: 'https://dlc.10086iot.net/clientfavorite?en=' + currentEm?.emNumber,
      img: '/pic/Customer.png',
      isBacklinks: true,
    },
    {
      label: '实名认证',
      url: '',
      img: '/pic/Realname.png',
      isModal: true,
    },
    {
      label: '切换网络',
      url: '/switch-network',
      img: '/pic/Internet.png',
    },
    {
      label: '网络检测',
      url: '',
      callBack: handleInspect,
      img: '/pic/equipment.png',
    },
    {
      label: '成为代理',
      url: 'https://wap.100861.net/invite?userId=1849006555355267074',
      isBacklinks: true,
      img: '/pic/broker.png',
    },
    {
      label: '我的订单',
      url: '/pay-order-list',
      img: '/pic/orders.png',
    },
    {
      label: '套餐记录',
      url: '/pack-order-list',
      img: '/pic/package.png',
    },
    {
      label: '消费记录',
      url: '/income-list',
      img: '/pic/Recording.png',
    },
    {
      label: '卡券中心',
      url: '/coupon-list',
      img: '/pic/Coupons.png',
    },
    {
      label: '设置',
      url: '/setting',
      img: '/pic/setUp.png',
    },
  ];

  const [tabValue, setTabValue] = useState('');
  const [shoppingCentr_data, setShoppingCentr_data] = useState<any[]>([]);

  const [tabLabelList, setTabLabelList] = useState<any[]>([]);
  async function getShopTypes() {
    try {
      let res = await apiGetgetGoodsClass();
      if (res.status === 200) {
        setTabLabelList(res.data?.data || []);
      }
    } catch (e: any) {}
  }
  async function getShopData(id: string | number) {
    try {
      let res = await apiGettGoodsList(id);
      if (res.status === 200) {
        setShoppingCentr_data(res.data?.data || []);
      }
    } catch (e: any) {}
  }

  return (
    <Page className="d-flex-c bg-gray home-page" contentClassName="d-flex-c flex-1 h-1 pos-r">
      <img className="bw-100 pos-a" src="/pic/pic_page_bg.png" alt="" />
      <div className="pos-a l-0 r-0 t-0 b-0 pl-20 pr-20 pt-20  overflow-y">
        <div className="d-flex-r ai-center">
          <div className={`w-8 h-8 border-radius-harf mr-10 ${currentEm?.status === 'Y' ? 'bg-green' : 'bg-red'}`} />
          <span className="font-size-12">设备号:{currentEm?.emNumber}</span>
          <img
            className="icon-12 ml-10"
            src="/pic/icon_action_copy.png"
            alt=""
            onClick={() =>
              copyToClipboard(currentEm?.emNumber!, () => {
                showToast('复制成功');
              })
            }
          />
          <div className="flex-1"></div>
          <div className="d-flex-r ai-center" onClick={() => navigate('/em-list')}>
            <img className="icon-12 mr-10" src="/pic/icon_swap.png" alt="" />
            <div className="text-color-p">切换设备</div>
          </div>
        </div>
        <div className="mt-20 d-flex-r">
          <div className="flex-1 h-100" style={{ padding: 10 }}>
            <div className="d-flex-r ai-start font-widget-600">
              <div className="font-size-48 ">
                {isUnlimit ? '不限量' : surplusFlow <= 0 ? 0 : formatNumber(surplusFlow)}
              </div>
              {!isUnlimit && <div className="font-size-16 mt-5">G</div>}
            </div>
            <div className="font-size-12">本期剩余流量</div>
          </div>
          <div className="flex-1 h-100 d-flex-c pb-30 pl-30 ai-center jc-center" style={{ padding: 10 }}>
            <Button
              className="btn-shadow"
              type="primary"
              size="lg"
              shape="circle"
              onClick={() => navigate('/select-pack')}
            >
              <div className="w-100">订购套餐</div>
            </Button>
          </div>
        </div>

        <div className="package-info bg-white border-radius-10">
          <div className=" package-info-l mt-10 mb-10">
            <div className="text-color-9 flex-1 text-center">当前套餐</div>
            <div className="font-widget-600 mt-5 font-size-16 text-center word-break">
              <NoticeBar
                text={currentEm?.packDetailName ?? '--'}
                speed={50}
                color={'#000'}
                className="NoticeBar"
                background="#fff"
              />
            </div>
            <div className="font-size-12 text-color-9 mt-5 text-center word-break">
              有效期：{currentEm?.endEffectTime ? dayjs(currentEm?.endEffectTime).format('YYYY-MM-DD') : '--'}
            </div>
            {/* <img className="pos-a r-10 b-30 icon-40" src="/pic/icon_curr_pack.png" alt="" /> */}
          </div>
          {/* <div className="pt-10"></div> */}
          <div className="  package-info-c mt-10 mb-10">
            <div className="text-color-9 flex-1 text-center">已用流量</div>
            <div className=" ai-end mt-5 text-center text">
              <span className="font-widget-600 font-size-22">{formatNumber(flowKTOG(currentEm?.usedFlow))}</span>
              <span className="font-size-12 font-widget-600 ml-5 mt-2">GB</span>
            </div>
            {/* <img className="r-10 b-10 pos-a icon-40" src="/pic/icon_flow.png" alt="" /> */}
          </div>
          <div className="package-info-r pt-10 pb-10 " onClick={() => navigate('/recharge')}>
            <div className="text-color-9 flex-1 text-center">当前余额</div>
            <div className="flex-1 text-center">
              <div className="flex-1  ai-end bw-100 text-center">
                <span className="font-size-22 font-widget-600">
                  {formatNumber(currentEm?.cardDetailWalletVo?.availableAmount)}
                </span>
                <span className="font-size-12 ml-5 mb-3">元</span>
              </div>
              <div className=" text-color-p text-underline">去充值</div>
            </div>
          </div>
        </div>
        <div className="bw-100 img-box">
          {oceanList?.left.length > 0 && (
            <div className="mt-10 inline-block img-show" style={oceanList?.right?.length > 0 ? {} : { width: '100%' }}>
              <Slider {...settings} infinite={oceanList?.left.length <= 1 ? false : true} className="bw-100">
                {oceanList?.left?.map((item, index) => {
                  return (
                    <div key={index}>
                      <img
                        className="bw-100"
                        src={item?.imgUrl}
                        alt=""
                        onClick={() => {
                          if (item?.type === 'no' || !item?.skipUrl) {
                            return false;
                          } else if (item?.type === 'in') {
                            navigate(item.skipUrl);
                          } else if (item?.type === 'out') {
                            window.location.href = item.skipUrl;
                          }
                        }}
                      />
                    </div>
                  );
                })}
              </Slider>
            </div>
          )}
          {oceanList?.right.length > 0 && (
            <div className="mt-10 inline-block img-show" style={oceanList?.left?.length > 0 ? {} : { width: '100%' }}>
              <Slider {...settings} infinite={oceanList?.right.length <= 1 ? false : true} className="bw-100">
                {oceanList?.right?.map((item, index) => {
                  return (
                    <div key={index}>
                      <img
                        className="bw-100"
                        src={item?.imgUrl}
                        alt=""
                        onClick={() => {
                          if (item?.type === 'no' || !item?.skipUrl) {
                            return false;
                          } else if (item?.type === 'in') {
                            navigate(item.skipUrl);
                          } else if (item?.type === 'out') {
                            window.location.href = item.skipUrl;
                          }
                        }}
                      />
                    </div>
                  );
                })}
              </Slider>
            </div>
          )}
        </div>
        {!!currentEm?.equipmentLogVo && <WifiInfo emId={currentEm?.id!} info={currentEm?.equipmentLogVo} />}

        <div className="d-flex-r mt-10">
          <div className="options-info bg-white border-radius-10">
            <ul>
              {options.map((item, index) => (
                <li
                  key={index}
                  onClick={async () => {
                    if (item.isBacklinks) {
                      window.location.href = item.url;
                    } else if (item.isModal) {
                      if (!currentEm?.packDetailId || !currentEm?.endEffectTime) {
                        let modalRes = await showConfirm({
                          title: '提示',
                          content: '请购买套餐后实名认证',
                          cancelBtnText: '取消',
                          confirmBtnText: '前往购买',
                        });
                        if (!modalRes) return;
                        navigate('/select-pack');
                        return;
                      }
                      navigate('/real-name');
                    } else if (item.callBack) {
                      item.callBack();
                    } else {
                      navigate(item.url);
                    }
                  }}
                >
                  <div className="img-box">
                    <img src={item.img} alt="" />
                  </div>
                  <span className="font-size-12">{item.label}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="d-flex-r mt-10">
          <div className="shoppingCentre-info bg-white ">
            <div className="shoppingCentre-title ml-10 mt-10 mb-5 font-size-18">商城</div>

            <TabBarChange
              className="mr-10"
              options={tabLabelList}
              value={tabValue}
              onChange={(v) => {
                setTabValue(v);
                getShopData(v);
              }}
            />
            <div className="shoppingCentre-data pt-10">
              {shoppingCentr_data.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="shoppingCentre-data-item mb-8 border-radius-10"
                    onClick={() => (window.location.href = `https://wap.100861.net/goods?goodsId=${item.id}&form=wsl`)}
                  >
                    <div className="shoppingCentre-data-item-t">
                      <img src={item?.goodsListImg} alt="" />
                    </div>
                    <div className="shoppingCentre-data-item-b">
                      <div className="shoppingCentre-data-item-title pl-5 mt-5">
                        {!!item.goodsTagIcon && (
                          <img
                            className="h-16 mr-5"
                            style={{ verticalAlign: 'middle' }}
                            src={GoodsTagIcon.getTagIcon(item.goodsTagIcon)}
                            alt=""
                          />
                        )}
                        {item.goodsTitle}
                      </div>
                      <div className="shoppingCentre-data-item-people mt-5 pr-5 pl-5 text-color-9 font-size-12">
                        已有{item?.virtualSales || 0}人下单
                      </div>

                      <div className="tags-box mt-5">
                        <div className="tags-box-l font-size-12">
                          {item?.goodsTags?.split(',')?.map((item: any) => {
                            return (
                              <span
                                key={item}
                                className="mr-5 mb-10 tags pv-1 ph-3 border-radius-4 font-size-12 text-color-red bg-color-red-t3"
                              >
                                {item}
                              </span>
                            );
                          })}
                        </div>

                        <div
                          className="shoppingCentre-data-item-share "
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/homepage-detail`, {
                              state: {
                                imgUrl: item.goodsDetailImg ?? '',
                                title: item.goodsTitle ?? '',
                                inviteUserId: '',
                                goodsId: item.id,
                              },
                            });
                          }}
                        >
                          <img src="/pic/icon_export.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="text-center text-color-9 p-20 font-size-12">
          <span onClick={handleCallPhone}>服务热线</span>
          <span className="ml-10 mr-10">|</span>
          <span onClick={handleToComplaint}>投诉建议</span>
        </div>
      </div>
      {hasChargeFirstActive && <FirstActiveFloat />}
      {!!showRecommendAdvDialog && <RecommendAdvDialog imgList={showRecommendAdvDialog} onOk={handleToBuyEmPack} />}
    </Page>
  );
}
