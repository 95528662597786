/** 为业务所配置的弹窗的类型 */
export enum CardProductAdvertiseType {
  /** 首页推荐 */
  Recommend = 'recommend',
  /** 订购推荐 */
  OrderPopup = 'orderPopup',
  /** 无 */
  isNull = 'isNull'
}
/** 为业务所配置的弹窗的类型 */
export enum CardProductAdvertiseStatus {
  /** 启用 */
  Enable = 'enable',
  /** 禁用 */
  Disable = 'disable'
}