import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import main from '../../main';
import { apiGetCardLibraryCustomer } from '../../api';
interface Props {
  onClick?: () => void;
  onComplaint?: () => void;
}

const itemWidth = 34;
const itemHeight = 100;
const space = 10;
export default function FloatCustomer({ onClick, onComplaint }: Props) {
  const location = useLocation();
  const divRef = useRef<any>();
  //卡库中的客服电话
  const [serverPhone, setServerPhone] = useState('');
  const [isLoginPage, setIsLoginPage] = useState(false);
  const [left, setLeft] = useState<number>(window.innerWidth - itemWidth - space); // 向左移动的距离
  const [top, setTop] = useState<number>(window.innerHeight - itemHeight - 100); // 向上移动的距离
  function handleClick(e: any) {
    e.stopPropagation();
    e.preventDefault();
    if (onClick) {
      onClick!();
    }
  }
  function handleComplaint(e: any) {
    e.stopPropagation();
    e.preventDefault();
    if (onClick) {
      onComplaint!();
    }
  }

  //开始拖拽
  function handleDragStart() {
    divRef!.current!.style.transition = 'none';
    document.body.style.overflow = 'hidden';
  }
  //触摸移动
  function handleTouchMove(e: React.TouchEvent<HTMLDivElement>) {
    if (e.targetTouches.length === 1) {
      let touch = e.targetTouches[0];
      let left = touch.clientX - itemWidth / 2;
      let top = touch.clientY - itemHeight / 2;
      if (left < space) {
        left = space;
      }
      if (left > window.innerWidth - itemWidth - space) {
        left = window.innerWidth - itemWidth - space;
      }
      if (top < space) {
        top = space;
      }
      if (top > window.innerHeight - itemHeight - space) {
        top = window.innerHeight - itemHeight - space;
      }
      setLeft(left);
      setTop(top);
    }
  }
  function hanldeTouchEnd() {
    document.body.style.overflow = '';
  }

  useEffect(() => {
    let _isLoginPage = location.pathname === '/login';
    setIsLoginPage(_isLoginPage);
    if (main.nextBindEm && _isLoginPage) {
      loadServerPhone();
    }
  }, [location]);

  async function loadServerPhone() {
    try {
      let res = await apiGetCardLibraryCustomer(main.nextBindEm!);
      let data = res.data;
      if (data.code === 200) {
        let _phone = (data.data ?? []).find((i: any) => !!i.serverPhone)?.serverPhone;
        if (!!_phone) {
          setServerPhone(_phone);
        }
      }
    } catch (e: any) {}
  }
  /**
   * 取值优先级：
   * 0. 卡库
   * 1. 系统参数配置的电话
   * 2. config.js配置的电话
   */
  function handleCallPhone() {
    let tel400 = serverPhone;
    if (!tel400 && main.configList) {
      let config = main.configList.find((i) => i.configKey === main.tel400Key);
      if (config) {
        tel400 = config.configValue!;
      }
    }
    if (!tel400) {
      tel400 = main.tel400;
    }
    window.open(`tel:${tel400}`);
  }

  return (
    <div
      className="bg-white d-shadow pos-f d-flex-c ai-center jc-center border-radius-8 shake-animation z-300"
      ref={divRef}
      style={{
        width: `${itemWidth}px`,
        height: `${itemHeight}px`,
        left: `${left}px`,
        top: `${top}px`,
      }}
      onTouchStart={(e) => handleDragStart()}
      onTouchMove={(e) => handleTouchMove(e)}
      onTouchEnd={(e) => hanldeTouchEnd()}
    >
      <div className="d-flex-c ai-center mt-5 text-color-p" onClick={handleClick}>
        <img className="icon-20" src="/pic/icon_customer.png" alt="" />
        <span className="font-size-12">客服</span>
      </div>
      {isLoginPage ? (
        <div className="d-flex-c ai-center mt-5 text-color-p" onClick={handleCallPhone}>
          <img className="icon-20" src="/pic/icon_complaint.png" alt="" />
          <span className="font-size-12">热线</span>
        </div>
      ) : (
        <div className="d-flex-c ai-center mt-5 text-color-p" onClick={handleComplaint}>
          <img className="icon-20" src="/pic/icon_complaint.png" alt="" />
          <span className="font-size-12">投诉</span>
        </div>
      )}
    </div>
  );
}
