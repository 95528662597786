import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiBindEm, apiGetEmList, apiGetWeChatSignature } from '../../../api';
import AppBar from '../../../components/appBar';
import Button from '../../../components/button';
import Page from '../../../components/page';
import main from '../../../main';
import { LoadingMask, delay, showToast } from '../../../utils/utils';

export default function AddEmPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [emNumber, setEmNumber] = useState('');
  const [desc, setDesc] = useState('');
  // 0 未准备 1可以使用  2 客户端不支持
  const [wxReady, setWxReady] = useState(0);
  useEffect(() => {
    if (!!main.nextBindEm) {
      loadEmList();
    }
    wxConfig();
  }, []); // eslint-disable-line
  async function loadEmList() {
    LoadingMask.show('加载中...');
    try {
      let res = await apiGetEmList();
      let data = res.data;
      if (data.code === 200) {
        let _list = data.data ?? [];

        // 优先验证绑卡，如果链接内带卡号 直接去绑卡
        if (!!main.nextBindEm) {
          let some = _list.some((i: EM) => i.emNumber === main.nextBindEm);
          if (!some) {
            setEmNumber(main.nextBindEm!);
          } else {
            main.nextBindEm = undefined;
            handleBack();
          }
        }
      } else {
        showToast(data.msg ?? '加载列表失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '加载列表失败');
    } finally {
      LoadingMask.hide();
    }
  }

  function handleBack() {
    if (location.state?.backToOut) {
      navigate(-1);
      return;
    }
    delay(100).then(() => {
      navigate('/home', { replace: true });
    });
  }

  useEffect(() => {
    if (!!main.nextBindEm && emNumber === main.nextBindEm) {
      main.nextBindEm = undefined;
      delay(1000).then((v) => handleAddEm());
    }
  }, [emNumber]); // eslint-disable-line

  async function wxConfig() {
    // @ts-ignore
    let wx = window.wx;
    let res = await apiGetWeChatSignature(
      main.isIos14 ? main.enterUrl : window.location.href.split('#')[0],
      main.appId
    );
    let data = res.data;

    if (data.code === 200) {
      let _data = data.data;
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: main.appId, // 必填，公众号的唯一标识
        timestamp: _data.timestamp, // 必填，生成签名的时间戳
        nonceStr: _data.nonceStr, // 必填，生成签名的随机串
        signature: _data.signature, // 必填，签名
        jsApiList: ['checkJsApi', 'scanQRCode'], // 必填，需要使用的JS接口列表
      });
    }

    wx.ready(() => {
      wx.checkJsApi({
        //判断当前客户端版本是否支持指定JS接口
        jsApiList: ['scanQRCode'],
        success: function (res: any) {
          // 以键值对的形式返回，可用true，不可用false。如：{"checkResult":{"scanQRCode":true},"errMsg":"checkJsApi:ok"}
          if (res.checkResult.scanQRCode !== true) {
            showToast('抱歉，当前客户端版本不支持扫一扫');
            setWxReady(2);
          } else {
            setWxReady(1);
          }
        },
      });
    });
  }
  // useEffect(() => {
  //   window.addEventListener('message', function (event) {
  //     const data = event.data;
  //     if (data) {
  //       console.log('接收到父页面消息:', data);
  //       // 在这里可以对数据进行进一步处理
  //     }
  //   });
  // }, [])
  function scanCode() {
  // if (!window.parent || !window.parent.postMessage) {
  //   return showToast('当前环境不支持扫码功能');
  // }
  // console.log('’点击', window)
  // window.parent.postMessage({
  //   type: 'scanCode',
  //   content: ''
  // }, '*');
  // // console.log(window, window.parent)
  // // debugger
  // return

    // debugger
    if (wxReady === 0) {
      return showToast('微信相机正在准备，请稍后再试');
    } else if (wxReady === 2) {
      return showToast('抱歉，当前客户端版本不支持扫一扫');
    }
    // @ts-ignore
    let wx = window.wx;
    // 调起企业微信扫一扫接口
    wx.scanQRCode({
      desc: 'scanQRCode desc',
      needResult: 1, // 默认为0，扫描结果由企业微信处理，1则直接返回扫描结果，
      scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
      success: function (res: any) {
        var result: string = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
        if (result.includes(',')) {
          result = result.split(',')[1];
        }
        setEmNumber(result);
      },
      error: function (res: any) {
        if (res.errMsg.indexOf('function_not_exist') > 0) {
          showToast('微信版本过低请升级');
        }
      },
    }); //wx.scanQRcode结束
  }
  async function handleAddEm() {
    if (!emNumber) {
      return showToast('请填写设备号');
    }
    LoadingMask.show('正在添加...');
    try {
      let res = await apiBindEm(emNumber, desc);
      let data = res.data;
      if (data.code === 200) {
        showToast(data.msg ?? '添加成功');
        delay(100).then(() => {
          navigate('/home', { replace: true });
        });
      } else {
        showToast(data.msg ?? '添加失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '添加失败');
    } finally {
      LoadingMask.hide();
    }
  }
  return (
    <Page appBar={<AppBar title="添加设备" onBack={handleBack} />}>
      <div className="h-10 bg-gray"></div>
      <div className="p-20 d-flex-c ai-center jc-center">
        <div className="text-center mb-10">扫一扫添加设备</div>
        <div className="w-250 h-130 d-shadow d-flex-r ai-center jc-center border-radius-10" onClick={scanCode}>
          <img className="w-125 h-70" src="/pic/pic_scan.png" alt="" />
        </div>
      </div>
      <div className="bg-white font-size-16 font-widget-600 pl-20 pt-20">设备信息</div>
      <div className="mt-12 ml-20 mr-20 d-flex-r border border-color-gray p-7 border-radius-5">
        <div className="w-120">设备号</div>
        <input
          onClick={scanCode}
          className="flex-1"
          type="text"
          value={emNumber}
          // disabled
          placeholder="请扫描设备二维码"
          onChange={main.isDev ? (e) => setEmNumber(e.target.value) : undefined}
        />
      </div>
      <div className="mt-12 ml-20 mr-20 d-flex-r border border-color-gray p-7 border-radius-5">
        <div className="w-120">设备描述(选填)</div>
        <input
          className="flex-1"
          type="text"
          value={desc}
          placeholder="用于区分多台设备"
          onChange={(e) => setDesc(e.target.value)}
        />
      </div>
      <div className="mt-40 pl-20 pr-20">
        <Button type="primary" long size="md" shape="circle" onClick={handleAddEm}>
          确认添加
        </Button>
      </div>
    </Page>
  );
}
