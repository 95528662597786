import { useEffect, useState } from 'react';
import { apiUpdateWifi } from '../../api';
import { LoadingMask, showToast } from '../../utils/utils';
interface Props {
  emId: string;
  info: WifiData;
}
export default function WifiInfo({ emId, info }: Props) {
  const [showDialog, setShowDialog] = useState(false);
  async function handleUpdatePassword(nname: string, npwd: string) {
    setShowDialog(false);
    if (nname === info.ssid && npwd === info.password) return showToast('未修改任何信息');
    LoadingMask.show('正在修改...');
    try {
      let res = await apiUpdateWifi(emId, nname, npwd);
      let data = res.data;
      if (data.code === 200) {
        showToast(data.msg || '修改成功');
      } else {
        showToast(data.msg || '修改失败');
      }
    } catch (e: any) {
      showToast(e.message || '修改失败');
    } finally {
      LoadingMask.hide();
    }
  }
  return (
    <div className="bg-white mt-10 border-radius-10 p-10 d-flex-r">
      {/* <div className="equipment-info-l pt-10 pl-10"> */}
      <img src="/pic/equipment_info.png" alt="" className='icon-40' />

      {/* </div> */}
      {/* <img src="/pic/equipment_info.png" className="icon-30 mr-10" alt="" /> */}
      <div className="flex-2 w-1" onClick={() => setShowDialog(true)}>
        <div className="mb-1 bw-100 omit-text pl-5">
          <span className="text-color-9 font-size-12">在线状态:</span>
          {info.wifiEnabled === '1' ? '在线' : '离线'}
        </div>
        <div className="bw-100 d-flex-r ai-center pl-5">
          <span className="text-color-9 font-size-12">连接设备:</span>
          <span className="flex-1 w-1 omit-text">{info.clientNumber}台</span>
        </div>
      </div>
      {/* <div className="flex-1 text-center">
        <div>{info.wifiEnabled === '1' ? '在线' : '离线'}</div>
        <div className="text-color-9 font-size-12">在线状态</div>
      </div>
      <div className="flex-1 text-center">
        <div>{info.clientNumber}台</div>
        <div className="text-color-9 font-size-12">连接设备</div>
      </div> */}
      <div className="flex-2 w-1" onClick={() => setShowDialog(true)}>
        <div className="mb-1 bw-100 omit-text">
          <span className="text-color-9 font-size-12">WIFI:</span>
          {info.ssid}
        </div>
        <div className="bw-100 d-flex-r ai-center">
          <span className="text-color-9 font-size-12">密码:</span>
          <span className="flex-1 w-1 omit-text">{info.password}</span>
          <img src="/pic/icon_edit.png" className="icon-16" alt="" />
        </div>
      </div>
      {!!showDialog && (
        <WifiPwdDialog
          pwd={info.password}
          name={info.ssid}
          onOk={handleUpdatePassword}
          onCancel={() => setShowDialog(false)}
        />
      )}
    </div>
  );
}
interface WifiPwdDialogProps {
  pwd: string;
  name: string;
  onOk: (name: string, pwd: string) => void;
  onCancel: () => void;
}
function WifiPwdDialog({ pwd, name, onOk, onCancel }: WifiPwdDialogProps) {
  const [nname, setNname] = useState(name);
  const [npwd, setNpwd] = useState(pwd);
  const [errorData, setErrorData] = useState('');

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  function handleConfirm() {
    if (!nname) {
      setErrorData('请输入WIFI名称');
      return;
    }
    if (!npwd) {
      setErrorData('请输入WIFI密码');
      return;
    }
    if (!/[0-9a-zA-Z]{8,}/.test(npwd)) {
      setErrorData('输入不低于八位，不可包含特殊字符');
      return;
    }
    onOk(nname, npwd);
  }

  return (
    <div className="pos-f z-9999 l-0 b-0 t-0 r-0 d-flex-r ai-center jc-center">
      <div className="pos-a  l-0 b-0 t-0 r-0 z-0 bg-black-t7" onClick={onCancel}></div>
      <div className="pos-r z-10 w-300 bg-white border-radius-10">
        <div className="d-flex-r p-10 ai-center">
          <img src="/pic/icon_close_black.png" className="icon-20 view-opacity-0" alt="" />
          <div className="flex-1 text-center">修改WIFI信息</div>
          <img src="/pic/icon_close_black.png" className="icon-20" alt="" onClick={onCancel} />
        </div>
        <div className="pos-r p-10">
          <div className="text-color-origin-red font-size-12 mb-5">
            注意：修改WIFI信息后，将在十分钟内生效，无需重复修改！
          </div>
          <div className="d-flex-r ai-center mb-10">
            <div className="text-color-9">WIFI名称</div>
            <div className="flex-1 border p-5 ml-10">
              <input type="text" className="bw-100" value={nname} onChange={(e) => setNname(e.target.value)} />
            </div>
          </div>
          <div className="d-flex-r ai-center mb-30">
            <div className="text-color-9">WIFI密码</div>
            <div className="flex-1 border p-5 ml-10">
              <input type="text" className="bw-100" value={npwd} onChange={(e) => setNpwd(e.target.value)} />
            </div>
          </div>
          <div className="pos-a l-80 b-28 text-color-red font-size-12">{errorData}</div>
        </div>
        <div className="d-flex-r ai-center jc-center text-center border-top">
          <div className="flex-1 text-color-9 p-10 border-right" onClick={onCancel}>
            取消
          </div>
          <div className="flex-1 text-color-p p-10" onClick={handleConfirm}>
            确定
          </div>
        </div>
      </div>
    </div>
  );
}
