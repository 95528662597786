import { useEffect, useState } from 'react';
import { NoticeBar } from 'react-vant';
interface OptionItem {
  id: string,
  parentId: string,
  sortName: string,
  sortIcon: string,
  children?: OptionItem[]
}
interface Props {
  className?: string;
  options: any[];
  optionExpands?: string[];
  value: string;
  secondMinNum?: number;
  onChange: (value: string) => void;
  badges?: (string | number)[];
  type?: 'primary' | 'origin-red';
}
export default function TabBarChange({
  className,
  options,
  badges,
  value,
  optionExpands,
  onChange,
  type = 'primary',
  secondMinNum,
}: Props) {
  const [list, setList] = useState<OptionItem[]>([]);
  const [secondData, setSecondData] = useState<OptionItem[]>([]);
  const [firstValue, setFirstValue] = useState<string>('');
  const [secondValue, setSecondValue] = useState<string>('');
  const secondMinNums = secondMinNum || 1
  useEffect(() => {
    let firstList: any = []
    let secondList: any = []
    options?.forEach(item => {
      if (item.parentId == '0') {
        firstList.push(item)
      } else {
        secondList.push(item)
      }
    })
    firstList = firstList.map((item: any) => {
      return {
        ...item,
        children: secondList.filter((item1: any) => item1.parentId === item.id)
      }
    })
    setList(firstList)
    if (value) {
      const data: any = options.find(item => {
        return item.id === value
      })
      if (data.parentId == '0') {
        setFirstValue(data.id)
      } else {
        setSecondValue(data.id)
        setSecondData(firstList?.find((item: any) => item.id == data.parentId)?.children || [])
        setFirstValue(data.parentId)
      }
      onChange(value)
    } else {
      setFirstValue(firstList?.[0]?.id || '')
      setSecondValue(firstList?.[0]?.children?.[0]?.id || '')
      setSecondData(firstList?.[0]?.children || [])
      onChange(firstList?.[0]?.children?.[0]?.id || '')
    }
  }, [options]);
  function firstChange(id: string) {
    setSecondData(list.find((item) => item.id === id)?.children || [])
    setFirstValue(id)
    setSecondValue(list.find((item) => item.id === id)?.children?.[0]?.id || '')
    onChange(list.find((item) => item.id === id)?.children?.[0]?.id || '')
  }
  return (
    <div className={`${className}`}>
      <div className={`tab-bar-block-change ${type}`}>
        {list.map((i, idx) => (
          <div key={i.id} className={`tab-bar-item ${i.id === firstValue ? 'active border-radius-5' : 'border-radius-5'} pos-r`} onClick={() => firstChange(i.id)}>
            <NoticeBar
              text={i.sortName ?? '--'}
              speed={50}
              style={{ width: '100%' }}
              color={'#000'}
              className="label font-size-12"
              background="#fff"
            />
            {/* <div className="label">
              <span className="title">{i.sortName}</span>
            </div> */}
          </div>
        ))}

      </div>
      {/* 查找并渲染 parentId 为 '0' 的选项 */}
      {secondData.length > 0 && secondData.length > secondMinNums ? (
        <div className={`tab-bar-block-change ${type} ${className}`}>
          {secondData?.map((i: any, idx: any) => (
            <div key={i.id} className={`tab-bar-item ${i.id === secondValue ? 'secondActive border-radius-5' : 'border-radius-5'} pos-r`} onClick={() => onChange(i.id)}>
              <div className="label">
                <span className="title font-size-12">{i.sortName}</span>
              </div>
            </div>
          ))}
        </div>
      ) : null}

    </div>

  );
}
