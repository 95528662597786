import { apiGetEmPackList, apiGetFirstActivityList, apiGetProductAdverties, apiIsActive } from '@/api';
import main from '@/main';
import { WaitOrderSense, waitOrderDetect } from '@/utils/waitOrderDetect';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '../../components/appBar';
import Button from '../../components/button';
import NoData from '../../components/noData';
import Page from '../../components/page';
import TabBar from '../../components/tabBar';
import { PackageType, RechargeType } from '../../utils/dict';
import { LoadingMask, formatNumber, showConfirm, showToast } from '../../utils/utils';
import { CardProductAdvertiseStatus, CardProductAdvertiseType } from '@/constants';
const tabOptions = ['本期可订购套餐', '下期可续费套餐'];
let historyTabValue = tabOptions[0];

enum ClickType {
  /** 继续订购 */
  continueOrder = 0,
  /** 特惠套餐 */
  specialOffer = 1,
  /** 重新选购 */
  rePick = 2,
}

export default function SelectPackPage() {
  const navigate = useNavigate();
  const [list, setList] = useState<EmPack[]>([]);
  const [tabValue, setTabValue] = React.useState(historyTabValue);
  // 分类列表
  const [catList, setCatList] = useState<CardPackType[]>([]);
  // 已选分类
  const [cat, setCat] = useState('');

  // 是否有首充活动 有的话就悬浮按钮 并判断是否直接跳转进去
  const [hasChargeFirstActive, setHasChargeFirstActive] = useState(false);
  // 活动内容
  const [activity, setActivity] = useState<ChargeFirst>();

  useEffect(() => {
    loadPackList();
    checkFirstActivity();
    loadActivity();
  }, []); // eslint-disable-line

  useEffect(() => {
    handleResetList();
  }, [list, tabValue]); // eslint-disable-line
  // 通过设备ID检查是否参与过活动 参与过的不再跳转
  async function checkFirstActivity() {
    try {
      let res = await apiIsActive(main.currentEm?.id!, RechargeType.first);
      setHasChargeFirstActive(!!res.data.data);
    } catch (e) {}
  }
  /// 通过设备ID获取活动信息 是否强制参与活动，优先以checkFirstActivity是否参与过活动判断
  async function loadActivity() {
    try {
      let res = await apiGetFirstActivityList(main.currentEm?.id!);
      let data = res.data;
      if (data.code === 200) {
        let _data = data.data;
        setActivity(_data);
      } else {
        showToast(data.msg ?? '获取活动失败,请重试');
      }
    } catch (e: any) {
      showToast(e.message ?? '获取活动失败,请重试');
    }
  }
  async function loadPackList() {
    LoadingMask.show('加载中...');
    try {
      let res = await apiGetEmPackList(main.currentEm?.id!);
      let data = res.data;
      if (data.code === 200) {
        let _list: EmPack[] = data.data ?? [];
        setList(_list);
      } else {
        showToast(data.msg ?? '获取套餐列表失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '获取套餐列表失败');
    } finally {
      LoadingMask.hide();
    }
  }

  /**
   * 重置列表 计算能显示的列表再统计出分类 主要是为了算分类
   */
  function handleResetList() {
    let _list = list;
    if (tabValue === tabOptions[0]) {
      // 买本月
      _list = list.filter((item) => {
        if (main.currentEm?.packDetailId && main.currentEm?.isExperience === 'Y') {
          // 本月是体验包 只能买非体验包的基础包
          return item.packageType === PackageType.basicpackage && item.isExperience !== 'Y';
        } else if (!main.currentEm?.packDetailId) {
          // 本月是空 可以买所有基础包 包括体验包
          return item.packageType === PackageType.basicpackage;
        } else if (!!main.currentEm?.packDetailId) {
          // 本月是基础包 只能买加油包
          return item.packageType === PackageType.fuelpack;
        }
        return false;
      });
    } else if (tabValue === tabOptions[1]) {
      _list = _list.filter((i) => i.packageType === PackageType.basicpackage && i.isExperience !== 'Y');
    }
    let _catList: CardPackType[] = [];
    let _catMap: { [x: string]: CardPackType } = {};
    for (let item of _list) {
      _catMap[item.cardPackTypeId ?? 'test'] = item.cardPackType ?? { id: 'test', name: '其他分类', sort: 99999 };
    }

    _catList = Object.values(_catMap);
    if (_catList.length <= 1) {
      _catList = [];
    }
    _catList.sort((a, b) => a.sort - b.sort);
    setCatList(_catList);
    if (_catList.length) {
      setCat(_catList[0].id);
    } else if (!_catList.length) {
      setCat('');
    }
  }

  // 如果所选择套餐命中了PC配置的套餐，弹窗进行提示
  async function selectTip(emPack: EmPack): Promise<{
    clickType: ClickType;
    info: any;
  } | null> {
    try {
      const res = await apiGetProductAdverties({
        emId: main.currentEm?.id!,
        type: CardProductAdvertiseType.OrderPopup,
      });
      if (res?.data?.code === 200) {
        const data = res?.data?.data;
        const { cardProductAdvertise } = data;
        const configPackIdList: string[] = cardProductAdvertise?.packDetailIds?.item?.map(
          (i: { cardPackDetailId: string; cardPackDetailName: string }) => i?.cardPackDetailId
        );
        if (
          emPack.id != null &&
          configPackIdList?.includes(emPack.id) &&
          cardProductAdvertise?.status === CardProductAdvertiseStatus.Enable
        ) {
          const clickRes = await showConfirm({
            title: cardProductAdvertise?.title ?? '温馨提示',
            content: cardProductAdvertise?.popUpPrompt,
            hideCancelBtn: true,
            hideConfirmBtn: true,
            customContent: <CustomContent />,
          });
          return {
            clickType: clickRes,
            info: cardProductAdvertise,
          };
        }
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }

    return null;
  }

  async function handleToBuy(emPack: EmPack) {
    let res: string = '';
    if (hasChargeFirstActive && activity?.posterImg && activity?.payDetails?.length) {
      res = await showConfirm({
        title: '',
        maskClose: true,
        contentClose: 'center',
        content: (
          <div style={{ margin: '0 -5.333vw -7vw -5.333vw' }}>
            <img className="bw-100" src={activity!.posterImg} alt="" />
          </div>
        ),
        hideCancelBtn: true,
        hideConfirmBtn: true,
      });
    }
    if (res !== 'center') {
      // 判断是否有待支付订单，如果有就关闭订单
      const isPass = await waitOrderDetect({ sense: WaitOrderSense.Buy });
      if (!isPass) return;
      const clickRes = await selectTip(emPack);
      const navigateConfirm = (emPack: EmPack) => {
        navigate('/pack-order-confirm', {
          state: { emPack, dateType: tabValue === tabOptions[0] ? 'now' : 'next' },
        });
      };
      if (clickRes?.clickType != null) {
        // 如果命中弹窗条件
        switch (clickRes.clickType) {
          case ClickType.specialOffer:
            const newEmPack = list.find((i) => i.id === clickRes?.info?.returnPackDetailId);
            if (newEmPack) {
              navigateConfirm(newEmPack);
            }
            break;
          case ClickType.continueOrder:
            navigateConfirm(emPack);
            break;
          case ClickType.rePick:
            break;
          default:
            // do nothing
            break;
        }
      } else {
        navigateConfirm(emPack);
      }
    } else {
      navigate('/activity');
    }
  }

  function buildListItem(i: EmPack, idx: number) {
    let priceStr: any = '';
    let priceType = '';
    let showUnderline = false;

    if (i.priceType === 'month') {
      priceStr = formatNumber(
        (i.discountPrice || i.salePrice || 0) / (i.packTimeType === 'day' ? i.dayNum ?? 1 : i.monthNum ?? 1)
      );
      priceType = '月均';
    } else if (i.priceType === 'day') {
      let diff = dayjs(i.endEffectTime).diff(dayjs(i.startEffectTime), 'day');
      priceStr = formatNumber((i.discountPrice || i.salePrice || 0) / (diff ?? 1));
      priceType = '日均';
    } else {
      priceStr = formatNumber(i.discountPrice || i.salePrice);
      if (!!i.discountPrice) {
        priceType = `¥${formatNumber(i.salePrice)}`;
        showUnderline = true;
      }
    }

    return (
      <div
        key={(i.id ?? '') + idx}
        className="mt-10 bg-white pt-26 pb-10 pl-14 pr-14 border border-radius-5 border-color-gray d-flex-r ai-center pos-r"
      >
        <div
          className={`pt-2 pb-2 pl-8 pr-8 ${
            i.packageType === PackageType.basicpackage
              ? i.isExperience === 'Y'
                ? 'bg-green'
                : 'bg-primary'
              : 'bg-origin'
          } text-color-white font-size-12 border-radius-tl-5 border-radius-br-5 pos-a l-0 t-0`}
        >
          {i.packageType === PackageType.basicpackage ? (i.isExperience === 'Y' ? '体验包' : '基础包') : '加油包'}
        </div>
        <div className="flex-1">
          <div className="d-flex-r">
            <div className="mr-10">{i.name}</div>
          </div>
          <div className="font-size-12 text-color-9 mt-5">
            有效期
            {dayjs(tabValue === tabOptions[0] ? i.startEffectTime : i.nextStartEffectTime).format('YYYY-MM-DD')}至
            {dayjs(tabValue === tabOptions[0] ? i.endEffectTime : i.nextEndEffectTime).format('YYYY-MM-DD')}
          </div>
        </div>
        <div className="text-right">
          <div className="font-size-16 text-color-red">
            <span className="font-size-12">{!!i.discountPrice ? '券后¥' : '¥'}</span>
            {priceStr}
          </div>
          <div className={`font-size-12 text-color-9 ${showUnderline ? 'text-deleteline' : ''}`}>{priceType}</div>
        </div>
        <Button className="ml-10" type="origin-red" size="xs" onClick={() => handleToBuy(i)}>
          订购
        </Button>
      </div>
    );
  }

  function buildGridItem(i: EmPack, idx: number) {
    let priceStr: any = '';
    if (i.priceType === 'month') {
      priceStr = (
        <div>
          <span className="font-size-20">
            {formatNumber((i.discountPrice || i.salePrice || 0) / (i.monthNum ?? 1))}
          </span>
          元/月
        </div>
      );
    } else if (i.priceType === 'day') {
      let diff = dayjs(i.endEffectTime).diff(dayjs(i.startEffectTime), 'day');
      priceStr = (
        <div>
          <span className="font-size-20">{formatNumber((i.discountPrice || i.salePrice || 0) / (diff ?? 1))}</span>元/天
        </div>
      );
    } else {
      priceStr = (
        <div>
          <span className="font-size-20">{formatNumber(i.discountPrice || i.salePrice)}</span>元
        </div>
      );
    }
    if (!!i.detailImg) {
      return (
        <div onClick={() => handleToBuy(i)} key={(i.id ?? '') + idx} className="w-157 mr-20 mb-20">
          <img className="bw-100 border-radius-5" src={i.detailImg} alt="" />
        </div>
      );
    }
    return (
      <div
        onClick={() => {
          handleToBuy(i);
        }}
        key={(i.id ?? '') + idx}
        className="w-157 bg-white mr-20 mb-20 pt-20 pb-20 pl-10 pr-10 border border-radius-5 border-color-gray pos-r d-flex-c ai-center"
      >
        <div
          className={`pt-2 pb-2 pl-8 pr-8 ${
            i.packageType === PackageType.basicpackage
              ? i.isExperience === 'Y'
                ? 'bg-green'
                : 'bg-transparent'
              : 'bg-transparent'
          } text-color-white font-size-12 border-radius-tl-5 border-radius-br-5 pos-a l-0 t-0 z-10`}
        >
          {i.packageType === PackageType.basicpackage ? (i.isExperience === 'Y' ? '体验包' : '') : ''}
        </div>
        <div className="font-size-14 font-widget-600">{i.name}</div>
        <div className="text-color-red mt-10">{priceStr}</div>
        <div className="font-size-12 text-color-6 mt-10 pl-5 pr-5">{i.remark ?? ''}</div>
        {!!i.cardPackTag && (
          <div className="pos-a l-0 z-10 h-20 w-75" style={{ top: '-10px' }}>
            <img className="pos-a l-0 t-0 w-75 h-20" src={i.cardPackTag.imgUrl} alt="" />
            <div className="pos-a l-5 t-0 b-0 word-nowrap line-height-20 font-size-10 text-color-white">
              {i.cardPackTag!.name ?? ''}
            </div>
          </div>
        )}
      </div>
    );
  }

  let _list = list;
  if (tabValue === tabOptions[0]) {
    // 买本月
    _list = list.filter((item) => {
      if (main.currentEm?.packDetailId && main.currentEm?.isExperience === 'Y') {
        // 本月是体验包 只能买非体验包的基础包
        return item.packageType === PackageType.basicpackage && item.isExperience !== 'Y';
      } else if (!main.currentEm?.packDetailId) {
        // 本月是空 可以买所有基础包 包括体验包
        return item.packageType === PackageType.basicpackage;
      } else if (!!main.currentEm?.packDetailId) {
        // 本月是基础包 只能买加油包
        return item.packageType === PackageType.fuelpack;
      }
      return false;
    });
  } else if (tabValue === tabOptions[1]) {
    // 买下期 只能买 非体验包的基础包
    _list = list.filter((item) => item.packageType === PackageType.basicpackage && item.isExperience !== 'Y');
  }
  if (!!cat) {
    _list = _list.filter((item) => {
      let a = cat === 'test' ? !item.cardPackTypeId : item.cardPackTypeId === cat;
      return a;
    });
  }

  return (
    <Page
      className="d-flex-c bg-gray"
      contentClassName="flex-1 d-flex-c h-1"
      appBar={
        <AppBar
          title="订购套餐"
          onBack={() => {
            historyTabValue = tabOptions[0];
            navigate(-1);
          }}
        />
      }
    >
      <TabBar
        type="origin-red"
        className="border-bottom"
        options={tabOptions}
        value={tabValue}
        onChange={(v) => {
          setTabValue(v);
          historyTabValue = v;
        }}
      />
      {!!catList.length && (
        <div className="pl-20 pr-20 bg-white">
          <div className="pt-10 pb-10 overflow-x d-flex-r word-nowrap">
            {catList.map((i, idx) => (
              <div
                key={i.id + idx}
                className={`pt-5 pb-5 pl-10 pr-10 mr-10 border-radius-40 border border-color-origin-red ${
                  i.id === cat ? 'bg-origin-red text-color-white' : 'bg-origin-red-t text-color-origin-red'
                }`}
                onClick={() => setCat(i.id)}
              >
                {i.name}
              </div>
            ))}
          </div>
        </div>
      )}
      <div className={`overflow-y ml-20 ${main.currentEm?.cardProductVo?.listType === 'list' ? 'mr-20' : 'pt-20'}`}>
        {!_list.length && <NoData tips="暂无可用套餐" />}
        {main.currentEm?.cardProductVo?.listType === 'list' ? (
          _list.map((i, idx) => buildListItem(i, idx))
        ) : (
          <div className="d-flex-r flex-wrap">{_list.map((i, idx) => buildGridItem(i, idx))}</div>
        )}
        {!!main.currentEm?.cardProductVo?.promptTitle && (
          <div className="text-center font-size-12 text-color-9 mt-30">
            {main.currentEm?.cardProductVo?.promptTitle}
          </div>
        )}
        {!!main.currentEm?.cardProductVo?.promptContent && (
          <div className="p-20 font-size-12 text-color-9">
            {(main.currentEm?.cardProductVo?.promptContent ?? '').split('\n').map((i, idx) => (
              <div key={idx}>{i}</div>
            ))}
          </div>
        )}
      </div>
    </Page>
  );
}

const CustomContent = (customContentProps: any) => {
  const { onOk } = customContentProps;

  const [time, setTime] = useState(10);
  useEffect(() => {
    const delayAutoClose = setTimeout(() => {
      if (time > 1) {
        setTime(time - 1);
      } else {
        onOk(ClickType.continueOrder);
      }
    }, 1000);
    return () => {
      clearTimeout(delayAutoClose);
    };
  }, [time]); // eslint-disable-line

  return (
    <div
      className="border-top"
      style={{
        width: '100%',
        padding: 4,
        paddingBottom: 10,
        boxSizing: 'border-box',
      }}
    >
      <div className="d-flex-r mb-8 pl-10 pr-10">
        <Button className="flex-1 mr-8" type="primary" outline onClick={() => onOk(ClickType.rePick)}>
          重新选购
        </Button>
        <Button type="primary" className="flex-1" onClick={() => onOk(ClickType.specialOffer)}>
          特惠套餐
        </Button>
      </div>
      <div className="pl-10 pr-10">
        <Button
          type="gray"
          long
          outline
          style={{
            color: '#999',
            border: '1px solid #999',
            boxSizing: 'border-box',
          }}
          onClick={() => onOk(ClickType.continueOrder)}
        >
          继续订购 ({time})
        </Button>
      </div>
    </div>
  );
};
