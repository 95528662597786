export interface AdvImg {
  imgUrl: string;
  packDetailId: string;
  packDetailName: string;
}

interface Props {
  imgList: AdvImg[];
  onOk: (value?: AdvImg) => void;
}
export default function RecommendAdvDialog({ imgList, onOk }: Props) {
  return (
    <div className="adv-dialog">
      <div className="mask" onClick={() => onOk()}></div>
      {imgList.map((item, index) => {
        return <img className="adv-img" key={index} src={item.imgUrl} alt="" onClick={() => onOk(item)} />;
      })}
      <div className="close-btn" onClick={() => onOk()}>
        <img className="icon" src="/pic/icon_close_black.png" alt="" />
      </div>
    </div>
  );
}
